import React,{useState, useEffect, useRef} from 'react';
import { useSelector,useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import moment from "moment";
import SelectedUserChatMsg from './SelectedUserChatMsg.js';
import SelectedUserChatFileMsg from './SelectedUserChatFileMsg.js';
import { dateHandler,msgDateHandler } from "../../utils/date";
import SocketContext from "../../context/SocketContext";
import {
  SearchLargeIcon,
  CloseIcon,
} from "../../svg";

export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

const SelectedUserChat = ({selectedChat,selectedUser}) => {

	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const endRef = useRef();
	const firstHighlightRef = useRef();
    const [highlightedMessages, setHighlightedMessages] = useState([]);
    const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchVisible, setSearchVisible] = useState(false);

	useEffect(() => {
	    scrollToBottom();
	}, [selectedChat]);

	useEffect(() => {
        if (searchQuery) {
            const highlightRefs = [];
            selectedChat.forEach((message, index) => {
                if (message.message.toLowerCase().includes(searchQuery.toLowerCase())) {
                    highlightRefs.push(index);
                }
            });
            setHighlightedMessages(highlightRefs);
            setCurrentHighlightIndex(0);
        } else {
            setHighlightedMessages([]);
            scrollToBottom();
        }
    }, [searchQuery, selectedChat]);

    useEffect(() => {
        if (highlightedMessages.length > 0 && currentHighlightIndex < highlightedMessages.length) {
            const messageIndex = highlightedMessages[currentHighlightIndex];
            const messageElement = document.getElementById(selectedChat[messageIndex]._id);
            if (messageElement) {
                messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [currentHighlightIndex, highlightedMessages, selectedChat]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && searchQuery) {
                setCurrentHighlightIndex((prevIndex) => (prevIndex + 1) % highlightedMessages.length);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [searchQuery, highlightedMessages]);

    const escapeRegExp = (string) => {
	    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	};

    const linkify = (text) => {
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        const phonePattern = /(\b\d{3}[-.]?\d{3}[-.]?\d{4}\b)/g;

        return text.split(/(\s+)/).map((part, index) => {
            if (urlPattern.test(part)) {
                return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
            } else if (phonePattern.test(part)) {
                return <a key={index} href={`tel:${part}`}>{part}</a>;
            }
            return part;
        });
    };

	const highlightSearchTerm = (text, term) => {
        if (!term) return linkify(text);

        const escapedTerm = escapeRegExp(term);
        const parts = text.split(new RegExp(`(${escapedTerm})`, 'gi'));
        let firstHighlightFound = false;

        return (
            <>
                {parts.map((part, index) => {
                    const linkedPart = linkify(part);
                    if (part.toLowerCase() === term.toLowerCase()) {
                        if (!firstHighlightFound) {
                            firstHighlightFound = true;
                            return (
                                <span key={index} ref={firstHighlightRef} style={{ backgroundColor: 'darkgoldenrod', display: 'inline-block' }}>
                                    {linkedPart}
                                </span>
                            );
                        } else {
                            return (
                                <span key={index} style={{ backgroundColor: 'darkgoldenrod', display: 'inline-block' }}>
                                    {linkedPart}
                                </span>
                            );
                        }
                    }
                    return linkedPart;
                })}
            </>
        );
    };

	const scrollToBottom = () => {
	    endRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	const currentTime = new Date().toLocaleTimeString();

    const toggleSearch = () => {
      setSearchVisible(!searchVisible);
    };

	return (
		<>
			<div className="messageBg">
            <div className="dashboardChatSearch chatSearch">
                    <button className="btn" onClick={toggleSearch}>
                      <SearchLargeIcon />
                    </button>
                    {searchVisible && (
                        <>
                    <input
                        type="text"
                        placeholder="Search messages..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="" onClick={toggleSearch}>
                        <CloseIcon />
                    </span>
                  </>
                )}
              </div>
            <div className="messageScroll" style={{ height: windowHeight.winHeight - 100 }}>
                {selectedChat && selectedChat.map((message, index) => {
                    const previousMessage = selectedChat[index - 1];
                    const showDateSeparator = !previousMessage || msgDateHandler(previousMessage.createdAt) !== msgDateHandler(message.createdAt);

                    return (
                        <div key={message._id}>
                            {showDateSeparator && (
                                <div className="date-separator">
                                    {msgDateHandler(message.createdAt)}
                                </div>
                            )}
                            <div className="position-relative" style={{ margin: '10px 0' }} id={message._id}>
                                {message.files.length > 0
                                    ? message.files.map((file, i) => (
                                        <SelectedUserChatFileMsg
                                            FileMessage={file}
                                            message={message}
                                            key={i}
                                            // confirmDelete={() => confirmDeleteFile(message._id, file.file)}
                                            me={selectedUser?._id === message.sender?._id}
                                        />
                                    ))
                                    : null}
                                {message.message.length > 0 ? (
                                    <SelectedUserChatMsg
                                        message={{ ...message, message: highlightSearchTerm(message.message, searchQuery) }}
                                        key={message._id}
                                        // confirmDelete={confirmDelete}
                                        me={selectedUser?._id === message.sender?._id}
                                    />
                                ) : null}
                            </div>
                        </div>
                    );
                })}
                {/*{typing === activeConversation._id ? <Typing /> : null}*/}
                <div className="mt-2" ref={endRef}></div>
            </div>
        </div>
		</>
	)
}

const SelectedUserChatWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <SelectedUserChat {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default SelectedUserChatWithContext;