import { useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  selectUser,
} from "../redux/features/auth/authSlice";

// export const ShowOnLogin = ({ children }) =>{
// 	const isLoggedIn = useSelector(selectIsLoggedIn);

// 	if (isLoggedIn) {
// 	    return <>{children}</>;
// 	}
// 	return null;
// };

// export const ShowOnLogout = ({ children }) =>{
// 	const isLoggedIn = useSelector(selectIsLoggedIn);

// 	if (!isLoggedIn) {
// 	    return <>{children}</>;
// 	}
// 	return null;
// };

// export const SuperAdminLink = ({ children }) => {
//   const isLoggedIn = useSelector(selectIsLoggedIn);
//   const user = useSelector(selectUser);

//   if (isLoggedIn && (user?.role === "superAdmin")) {
//     return <>{children}</>;
//   }
//   return null;
// };

// export const AdminLink = ({ children }) => {
//   const isLoggedIn = useSelector(selectIsLoggedIn);
//   const user = useSelector(selectUser);

//   if (isLoggedIn && (user?.role === "admin")) {
//     return <>{children}</>;
//   }
//   return null;
// };

export const FilterUser = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);

  // if (isLoggedIn && (user?.role !== "student")) {
  //   return <>{children}</>;
  // }
  // const isStudent = user?.roles?.includes('student');

  // if (isLoggedIn && isStudent) {
  //   return <>{children}</>;
  // }
  const isStudent = user?.roles?.includes('student');
  if (isLoggedIn) {
    if (isStudent) {
      return null; // Show A (children content) if user has 'student' role
    } else {
      return <>{children}</>; // Show B if user does not have 'student' role
    }
  }
  return null;
};

export const FilterAdmin = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  const isUser = user?.roles?.includes('admin') || user?.roles?.includes('superadmin');
  if (isLoggedIn) {
    if (isUser) {
      return <>{children}</>; // Show B if user does not have 'student' role
    } else {
      return null; // Show A (children content) if user has 'student' role
    }
  }
  return null;
};