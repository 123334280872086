import React,{useEffect,useState} from 'react';
import {useParams,Link,useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { Modal } from 'react-bootstrap';
import useRedirectLoggedOutUser from "../customHook/useRedirectLoggedOutUser";
import { useDispatch,useSelector } from "react-redux";
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import {
  getLoginStatus,selectIsLoggedIn,login,RESET
} from "../redux/features/auth/authSlice";

const meetingUrl = process.env.REACT_APP_BACKEND_URL;
const appID = Number(process.env.REACT_APP_ID_ZEGO);
const serverSecret = process.env.REACT_APP_SECRET_KEY_ZEGO;

const initialState = {
  email: "",
  password: "",
};

const Room = () => {

	// useRedirectLoggedOutUser("/login");

	const [show, setShow] = useState(false);

	const showPassword = () => setShow(!show);

	const [formData, setFormData] = useState(initialState);
	const {email, password} = formData;
	const {roomId} = useParams();
	const { user, isLoading, isSuccess } = useSelector((state) => state.auth);
	const [ViewLoginModel, SetLoginModelShow] = useState(false)
    const handleLoginModelShow = () => { SetLoginModelShow(true) }
    const hanldeLoginModelClose = () => { SetLoginModelShow(false) }

	const dispatch = useDispatch();
	const navigate = useNavigate();
  	const isLoggedIn = useSelector(selectIsLoggedIn);
	  // console.log(user);
	// useEffect(() => {
	//     if (user) {
  //       	SetLoginModelShow(false);
  //       }else {
  //           SetLoginModelShow(true);
  //       }

	// }, [user]);

	useEffect(() => {
    if (!user) {
        // If the user is not logged in, show the login modal
        handleLoginModelShow();
    } else {
        // If the user is logged in, join the meeting
        const element = document.querySelector(".myCallContainer");
        if (element) {
            myMeeting(element);
        }
    }
}, [user]);

	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	const loginUser = async (e) => {
		e.preventDefault();

		if (!email || !password) {
	      return toast.error("All fields are required");
	    }

	    const userData = {
	      email,
	      password,
	    };

	    // console.log(userData);
	    await dispatch(login(userData));
	}

	useEffect(() =>{
		if (isSuccess && isLoggedIn) {
	        hanldeLoginModelClose();
	    }
	    dispatch(RESET());
	},[isLoggedIn, isSuccess, dispatch])


	const myMeeting = async (element) => {
		// const appID = 278626295;
      	// const serverSecret = "cba897987fc459da6c0b147950e6c7bf";
      	// generate Kit Token
      	const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomId,  user?._id,  user?.name);

      	// Create instance object from Kit Token.
      	const zc = ZegoUIKitPrebuilt.create(kitToken);

      	// start the meeting
	    zc?.joinRoom({
	        container: element,
	        // showRoomTimer: true,
	        sharedLinks: [
	          {
	            name: 'Copy link',
	            // url:
	            //  window.location.protocol + '//' + 
	            //  window.location.host + window.location.pathname +
	            //   '?roomId=' +
	            //   roomId,
	            url:`${meetingUrl}/room/${roomId}`
	          },
	        ],
	        scenario: {
	          mode: ZegoUIKitPrebuilt.GroupCall, // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
	        },
	    });
		}

	return (
		<>
			<div className="">
				<h5 className="text-center mt-4">Room {roomId}</h5>
				<Link to="/">Back</Link>
				<div
			      className="myCallContainer my-2"
			      ref={myMeeting}
			      style={{ width: '100%', height: '100vh'}}
			    >
			    </div>
		    </div>

		    {/*<!-- Modal -->*/}
		    <Modal show={ViewLoginModel}
                    // onHide={hanldeDndModelClose}
                    backdrop="static"
                    keyboard={false}
                    className="loginRoomModal"
                    >
				<h4 className="mb-3">Log-In</h4>
			    <form onSubmit={loginUser} className="col-md-12 col-sm-6 col-12 mx-auto">
				  <div className="mb-3">
				    <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={handleInputChange}/>
				  </div>
				  <div className="mb-3 passwordField">
				    <input type={show? "text": "password"} name="password" className="form-control" placeholder="Password" value={password} onChange={handleInputChange}/>
				    <button type="button" onClick={showPassword}>{show ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}</button>
				  </div>
				
				  <button type="submit" className="btn btn-secondary">
				  {isLoading === true ? (
	              	<PulseLoader color="#fff" size={16} />
	            	) : (
					  'Login'
					  )}
	              </button>
				</form>
			</Modal>
		</>
	)
}

export default Room