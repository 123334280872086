import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { dateHandler } from "../../utils/date";
import { toast } from "react-toastify";
import DownloadIcon from "../../svg/Download";


export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

export const FileImageVideo = ({ url, type, name }) => {
	return (
		<>
			<div className="">
				{type === "IMAGE" ? (
					<a href={url} target="_blank">
			        	<img src={url} alt={name}/>
					</a>
			      ) : (
			        <video src={url} controls></video>
			      )}
			</div>
		</>
	)
}

export const FileOthers = ({ file, type, me, name, size }) => {

// 	function handleDownload(url) {
//   // Initiate download
//   window.open(url, '_blank');
// }

// 	function handleDownloadError(error) {
//   console.error('Error occurred during download:', error);
//   // You can handle the error here, such as showing an error message to the user.
// }
	const downloadFile = () => {
    const fileUrl = file; // Replace with your file URL
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a temporary anchor element
        const link = document.createElement('a');
        // Set the href attribute to the blob URL
        link.href = url;
        // Set the download attribute to force the browser to download the file
        link.setAttribute('download', name); // Replace 'filename.pdf' with your desired file name
        // Hide the anchor element
        link.style.display = 'none';
        // Append the anchor element to the body
        document.body.appendChild(link);
        // Trigger a click on the anchor element
        link.click();
        // Remove the anchor element from the DOM
        document.body.removeChild(link);
        console.log(`${file.name}.${type}`);
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  };
	return (
		<>
			<div className="fileMessageBox">
				<img
		            src={`../../../fileImages/${type}.png`}
		            alt=""
		            className=""
		          />
		        <div className="fileMessageDetail">
		          	<p>
		        	{name}
			        </p>
			        <span>{size} - KB</span> 
		        </div>
		        {/*{me && (*/}
		        	<button onClick={downloadFile}>
				      <DownloadIcon />
				    </button>
		          {/*<a href={file} download >
		            <DownloadIcon />
		          </a>*/}
		        {/*)}*/}
		        {/*{!me && (
		          <a href={file.secure_url} target="_blank" download onClick={() => handleDownload(file.secure_url)} onError={handleDownloadError}>
		            <DownloadIcon />
		          </a>
		        )}*/}
			</div>	
		</>
	)
}

const SelectedUserChatFileMsg = ({FileMessage, message, me}) => {

	const { file, type, name, size } = FileMessage;

	const formattedDate = message.seen 
    ? `Read on ${new Date(message.updatedAt).toLocaleString('en-US', { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit' 
      })}` 
    : '-';

	return (
		<>
			<div className={`d-flex ${me ? "ml-auto justify-content-end " : ""}`}>
				{!me && message.conversation?.isGroup && (
		          <div className="senderImg">
		            <img
		              src={message.sender.picture}
		              alt=""
		              className="w-8 h-8 rounded-full"
		            />
		          </div>
		        )}
				<div className={`position-relative textBox ${me ? "messageBg1" : "messageBg2"}`} id={message._id} title={formattedDate}>
				{!me && message.conversation?.isGroup && (<small className="grpUserName">{shortenText(message.sender.name, 12)}</small>)}
				<div className={`d-flex ${me ? "flex-row-reverse" : "flex-row"} justify-space-between`}>
					<div className="">
						{type === "IMAGE" || type === "VIDEO" ? (
			              <FileImageVideo name={name} url={file} type={type} />
			            ) : (
			              <FileOthers size={size} name={name} file={file} type={type} me={me} />
			            )}
						{/*<span className="text-white">{moment(message.createdAt).format("HH:mm")}</span>*/}
						<span className="text-white">{message?.createdAt ? dateHandler(message?.createdAt) : " "}</span>
						<div className="message-info">
                {me && (
                    <span className="message-status">
                        {message.seen ? '✓✓' : '✓'}
                    </span>
                )}
            </div>
					</div>
				</div>
				</div>
			</div>
		</>
	)
}

export default SelectedUserChatFileMsg