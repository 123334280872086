import React,{useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getConversations,open_contact } from "../../redux/features/chat/chatSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { dateHandlerLatestMessage } from "../../utils/date";
import SocketContext from "../../context/SocketContext";
// import {
//   getConversationId,
//   getConversationName,
//   getConversationPicture,
// } from "../../utils/chat";

// export const getConversationName = (currentUser, users) => {
//   // Filter out the current user and return the name of the other user
//   const otherUser = users.find(u => u._id !== currentUser._id);
//   return otherUser ? otherUser.name : 'Unnamed User';
// };

export const getConversationName = (selectedUser, users) => {
  return users[0]?._id === selectedUser?._id ? users[1]?.name : users[0]?.name;
};
export const getConversationPicture = (selectedUser, users) => {
  return users[0]?._id === selectedUser?._id ? users[1]?.picture : users[0]?.picture;
};

const DashboardSideBar = ({socket,handleChatSelect,activeChatId,selectedUser,setSelectedUser}) => {

	const [searchResults, setSearchResults] = useState([]);
	const [userConversations, setUserConversations] = useState([]);
	const { user } = useSelector((state) => state.auth);
	const { conversations, activeConversation, messages } = useSelector((state) => state.chat);
	const dispatch = useDispatch();

	const [filteredConversations, setFilteredConversations] = useState([]);
  const [startDate, setStartDate] = useState(""); // State for start date
  const [endDate, setEndDate] = useState(""); // State for end date

	useEffect(() => {
	      dispatch(getConversations());
	}, [dispatch]);

	// const values = {
	//     receiver_id: selectedUser?._id,
	//     // user:user.token,
	// };

	useEffect(() => {
    if (selectedUser) {
      const fetchConversations = async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/conversation/conversations?user=${selectedUser._id}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
          setUserConversations(data);
        } catch (error) {
          toast.error('Failed to Load Conversations');
        }
      };
      fetchConversations();
    } else {
      setUserConversations([]);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (startDate && endDate) {
      const filtered = userConversations.filter(convo => {
        const convoDate = new Date(convo.latestMessage?.createdAt);
        return convoDate >= new Date(startDate) && convoDate <= new Date(endDate);
      });

      // Sort conversations by latest message date (most recent first)
      const sorted = filtered.sort((a, b) => new Date(b.latestMessage?.createdAt) - new Date(a.latestMessage?.createdAt));
      setFilteredConversations(sorted);
    } else {
      // Sort all conversations by latest message date (most recent first)
      const sorted = userConversations.sort((a, b) => new Date(b.latestMessage?.createdAt) - new Date(a.latestMessage?.createdAt));
      setFilteredConversations(sorted);
    }
  }, [startDate, endDate, userConversations]);

	const handleSearch = async (e) => {
	    if (e.target.value && e.key === "Enter") {
	      try {
	        const { data } = await axios.get(
	          `${process.env.REACT_APP_BACKEND_URL}/api/users?search=${e.target.value}`,
	          {
	            headers: {
	              Authorization: `Bearer ${user.token}`,
	            },
	          }
	        );
	        setSearchResults(data);
	      } catch (error) {
	        return toast.error("Failed to Load the Search Results");
	      }
	    } else {
	      setSearchResults([]);
	    }
	};

	const handleUserSelect = (user) => {
	    setSelectedUser(user);
	    setSearchResults([]);
	};

	const resetFilters = () => {
    setStartDate("");
    setEndDate("");
  };

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	// console.log(activeConversation)
	
	return (
		<>
			<div className="dashboardSidebar">
				<div className="dashboardSidebarUser">
					<div className="dateFilter pt-3">
					<div className="d-flex align-items-center justify-content-between mb-2">
						<p className="mb-0">Start Date</p>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
					</div>
					<div className="d-flex align-items-center justify-content-between mb-2">
						<p className="mb-0">End Date</p>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
					</div>
            <button className="btn" type="button" onClick={resetFilters}>Reset</button>
          </div>
					<div className="my-3">
				    	<input type="text" className="form-control" onKeyDown={(e) => handleSearch(e)} placeholder="Search User"/>
				  	</div>
					<div className="adminUserSearch" style={{overflowY: 'scroll',height: searchResults && searchResults.length > 0 ? '150px' : 'auto'}}>
						<div 
							  style={searchResults && searchResults.length > 0 
							    ? { border: '1px solid lightgrey', borderRadius: '4px', padding: '10px' } 
							    : {}}
							>
							{searchResults &&
					            searchResults.map((user) => (
					            <div className="grpSearchUserList" key={user._id} onClick={() => handleUserSelect(user)}>
									<img src={user.picture} />
			    					<div style={{lineHeight : '1', marginLeft : '10px'}}>
			    						<p>{user.name}</p>
			    						<small>{user.email}</small>
			    					</div>
								</div>
					          ))}
						</div>
					</div>
					{selectedUser && (
		              <div className="selectedUserChat" style={{height : windowHeight.winHeight - 300}}>
		                <h5>Conversations with {selectedUser.name}</h5>
		                {filteredConversations.length > 0 ? (
		                  <ul>
		                    {filteredConversations.map((chat) => (
		                    <li key={chat._id} id={chat._id} onClick={() => handleChatSelect(chat._id)} className={chat._id === activeChatId ? "activeChat" : ""}>
								<div className={`chatImg`}>
									<img src={chat.isGroup ? chat.picture : getConversationPicture(user, chat.users)} alt="Picture"/>
								</div>
								<div className="chatDetail">
									<h6>{chat.isGroup ? chat.name : getConversationName(selectedUser, chat.users)}</h6>	
									<p>
									  {chat.latestMessage ? (
									    chat.latestMessage.files && chat.latestMessage.files.length > 0 ? (
									      chat.latestMessage.files.map((file, index) => (
									        <span key={index}>
									          {index > 0 ? ", " : ""}
									          {file.name}
									        </span>
									      ))
									    ) : (
									      chat.latestMessage.message && chat.latestMessage.message.length > 0 ? (
									        chat.latestMessage.message.length > 25 ?
									          `${chat.latestMessage.message.substring(0, 25)}...` :
									          chat.latestMessage.message
									      ) : (
									        ""
									      )
									    )
									  ) : (
									    ""
									  )}
									</p>				
								</div>
								<div className="chatDate">
									<small>{chat.latestMessage?.createdAt ? dateHandlerLatestMessage(chat.latestMessage?.createdAt) : " "}</small>
								</div>
								
							</li>
		                    ))}
		                  </ul>
		                ) : (
		                  <p>No conversations found.</p>
		                )}
		              </div>
		            )}
				</div>
			</div>	
		</>
	)
}

const DashboardSideBarWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <DashboardSideBar {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default DashboardSideBarWithContext