import React,{useState,useEffect} from 'react';
import { useNavigate,useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch, useSelector } from "react-redux";
import { login, RESET } from "../../redux/features/auth/authSlice";
import axios from "axios";

const initialState = {
  email: "",
  password: "",
};

const LoginForm = () => {

	const [show, setShow] = useState(false);

	const showPassword = () => setShow(!show);

	const [formData, setFormData] = useState(initialState);
	const {email, password} = formData;
	// const {id} = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// console.log(id);
	const { isLoading, isLoggedIn, isSuccess } =
    useSelector((state) => state.auth);
	
	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	const loginUser = async (e) => {
		e.preventDefault();

	    try{
	    	if (!email || !password) {
		      return toast.error("All fields are required");
		    }

		    const userData = {
		      email,
		      password,
		    };

		    // console.log(userData);
		    await dispatch(login(userData));

	    }catch(error){
				toast.error(error.message);
			}
	}

	useEffect(() =>{
    if (isSuccess && isLoggedIn) {
        navigate("/"); // Navigate to the desired route
        window.location.reload(); // Refresh the page after navigating
    }
    dispatch(RESET());
},[isLoggedIn, isSuccess, dispatch, navigate])

	// useEffect(() => {
	//   const fetchUserById = async () => {
	//     try {
	//       console.log('Fetching user by ID:', id);
	//       const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`);
	//       console.log('User data:', response.data);
	//       const userData = response.data;
	//       // Automatically log in the user
	//       await dispatch(login(userData));
	//       console.log('User logged in successfully:', userData);
	//     } catch (error) {
	//       console.error('Error fetching user:', error);
	//     }
	//   };

	//   if (id) {
	//     fetchUserById();
	//   }
	// }, [id, dispatch]);
	
	return (
		<>
		<div className="container">
			<div className="authForm d-flex flex-column align-items-center justify-content-center">
				<img src="../../fileImages/ACDAPlogoFinal.png" />
				<h5 className="my-5">Use your EMS Login Details to Login to chat</h5>
				<form onSubmit={loginUser} className="col-md-5 col-sm-6 col-12 mx-auto">
				  <div className="mb-3">
				    <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={handleInputChange}/>
				  </div>
				  <div className="mb-3 passwordField">
				    <input type={show? "text": "password"} name="password" className="form-control" placeholder="Password" value={password} onChange={handleInputChange}/>
				    <button type="button" onClick={showPassword}>{show ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}</button>
				  </div>
				
				  <button type="submit" className="btn">
				  {isLoading === true ? (
	              	<PulseLoader color="#fff" size={10} />
	            	) : (
					  'Login'
					  )}
	              </button>
				</form>
			</div>
		</div>
		</>
	)
}

export default LoginForm