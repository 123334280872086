import React,{useState,useEffect,useRef} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { dateHandlerLatestMessage } from "../utils/date";
import { open_create_conversation, deleteConversation } from "../redux/features/chat/chatSlice";
import {
  getConversationId,
  getConversationName,
  getConversationPicture,
} from "../utils/chat";
import ChatLoading from "./ChatLoading";
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "../redux/features/auth/authSlice";
import SocketContext from "../context/SocketContext";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FilterUser } from "./hiddenLinks";
import axios from 'axios';

const Conversation = ({chat, socket, online, typing, isHidden, setIsHidden, unreadCount, setUnreadCount,defaultUsers}) => {

	const dispatch = useDispatch();
	// const { user } = useSelector((state) => state.auth);
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const user = useSelector(selectUser);
	// const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const { activeConversation } = useSelector((state) => state.chat);
  // const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [isMessageBold, setIsMessageBold] = useState(false);
  const [defaultResults, setDefaultResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const notificationListenerAdded = useRef(false);
  // const messageTone = new Audio('../audio/notification.mp3');
  // const [isDisabled, setIsDisabled] = useState(true);
  	// const {token} = user;
  	// useEffect(() => {
	  //   dispatch(getLoginStatus());
	  //   if (isLoggedIn && user === null) {
	  //     dispatch(getUser());
	  //   }

	  // }, [dispatch, isLoggedIn, user]);

  	const values = {
	    receiver_id: getConversationId(user, chat.users),
	    isGroup: chat.isGroup ? chat._id : false,
	    // token,
	};
	// console.log(chat)
	const openConversation = async () => {
	  // let newChat = await dispatch(open_create_conversation(values));
	  // socket.emit("join conversation", newChat.payload?._id);
	  // setIsHidden(!isHidden);

	  // socket.emit("read messages", user?._id); 
    // // Reset unread message count locally
    // setUnreadCount((prevUnreadCounts) => ({
    //   ...prevUnreadCounts,
    //   [chat._id]: 0,
    // }));
    try {
      let newChat = await dispatch(open_create_conversation(values));
      socket.emit("join conversation", newChat.payload?._id);
      setIsHidden(!isHidden);

      socket.emit("read messages", user?._id);

      // Reset unread message count locally
      setUnreadCount((prevUnreadCounts) => ({
        ...prevUnreadCounts,
        [chat._id]: 0,
      }));

      // Update the seen field to true in the database
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/conversation/seen/${newChat.payload?._id}`, {
        userId: user?._id
      });
    } catch (error) {
      console.error("Error opening conversation:", error);
    }
	};

	// const unreadCount = unreadCounts[chat._id] || 0;

  // useEffect(() => {
  //   // Event listener for receiving notifications
  //   socket.on("receive notification", (notification) => {
  //     if (notification.conversationId === chat._id) {
  //     	messageTone.play();
  //       setUnreadCount(prevUnreadCounts => ({
  //         ...prevUnreadCounts,
  //         [chat._id]: notification.unreadCount
  //       }));
  //     }
  //   });

  //   // Clean up event listeners when component unmounts
  //   return () => {
  //     socket.off("receive notification");
  //   };
  // }, [chat._id, setUnreadCount]);

//   useEffect(() => {

//   // const handleNotification = (notification) => {
//   //   if (notification.conversationId === chat._id && notification.sender_id !== user?._id) {
//   //     messageTone.play();
//   //     if (chat._id !== activeConversation?._id) {
//   //       setUnreadCount(prevUnreadCounts => ({
//   //         ...prevUnreadCounts,
//   //         [chat._id]: prevUnreadCounts[chat._id] ? prevUnreadCounts[chat._id] + 1 : 1,
//   //       }));
//   //     }
//   //   }
//   // };

//   	const handleNotification = (notification) => {
// 		  if (notification.conversationId === chat._id && notification.sender_id !== user?._id) {
// 		    messageTone.play();
// 		    if (chat._id !== activeConversation?._id) {
// 		      setUnreadCount(prevUnreadCounts => ({
// 		        ...prevUnreadCounts,
// 		        [chat._id]: prevUnreadCounts[chat._id] ? prevUnreadCounts[chat._id] + 1 : 1,
// 		      }));

// 		      // Check if browser supports notifications
// 		      if ('Notification' in window && Notification.permission === 'granted') {
// 		        new Notification('New Message', {
// 		          body: `New message from ${getConversationName(user, chat.users)}`,
// 		        });
// 		      } else if ('Notification' in window && Notification.permission !== 'denied') {
// 		        Notification.requestPermission().then(permission => {
// 		          if (permission === 'granted') {
// 		            new Notification('New Message', {
// 		              body: `New message from ${getConversationName(user, chat.users)}`,
// 		            });
// 		          }
// 		        });
// 		      }
// 		    }
// 		  }
// 		};

// 	// const handleDisconnect = () => {
// 	//   // Handle disconnection, e.g., update UI to show disconnected status
// 	//   console.log("Socket disconnected");
// 	// };

//   // Event listener for receiving notifications
//   // socket.on("receive notification", handleNotification);

//   // socket.on("disconnect", handleNotification);

//   // // Clean up event listeners when component unmounts
//   // return () => {
//   // 	// socket.off("receive notification", handleNotification);
//   //   socket.off("disconnect", handleNotification);
//   // };
// 		if (!notificationListenerAdded.current) {
//       socket.on("receive notification", handleNotification);
//       socket.on("disconnect", handleNotification);
//       notificationListenerAdded.current = true;
//     }

//     return () => {
//       // socket.off("receive notification", handleNotification);
//       socket.off("disconnect", handleNotification);
//       notificationListenerAdded.current = false;
//     };
// }, [chat._id, setUnreadCount, socket]);

	// const handleNotification = (notification) => {
  //   if (notification.conversationId === chat._id && notification.sender_id !== user?._id) {
  //     messageTone.play();
  //     setUnreadCount(prevUnreadCounts => ({
  //       ...prevUnreadCounts,
  //       [chat._id]: prevUnreadCounts[chat._id] ? prevUnreadCounts[chat._id] + 1 : 1,
  //     }));

  //     if ('Notification' in window && Notification.permission === 'granted') {
  //       new Notification('New Message', {
  //         body: `New message from ${getConversationName(user, chat.users)}`,
  //       });
  //     } else if ('Notification' in window && Notification.permission !== 'denied') {
  //       Notification.requestPermission().then(permission => {
  //         if (permission === 'granted') {
  //           new Notification('New Message', {
  //             body: `New message from ${getConversationName(user, chat.users)}`,
  //           });
  //         }
  //       });
  //     }
  //   }
  // };

//   const handleNotification = (notification) => {
//   if (notification.conversationId === chat._id && notification.sender_id !== user?._id) {
//     messageTone.play();
//     setUnreadCount(prevUnreadCounts => ({
//       ...prevUnreadCounts,
//       [chat._id]: prevUnreadCounts[chat._id] ? prevUnreadCounts[chat._id] + 1 : 1,
//     }));

//     if ('Notification' in window) {
//       if (Notification.permission === 'granted') {
//         new Notification('New Message', {
//           body: `New message from ${getConversationName(user, chat.users)}`,
//         });
//       } else if (Notification.permission !== 'denied') {
//         Notification.requestPermission().then(permission => {
//           if (permission === 'granted') {
//             new Notification('New Message', {
//               body: `New message from ${getConversationName(user, chat.users)}`,
//             });
//           }
//         }).catch(error => {
//           console.error('Notification permission request error:', error);
//         });
//       }
//     } else {
//       console.warn('This browser does not support notifications.');
//     }
//   }
// };


// 	useEffect(() => {
//     if (!notificationListenerAdded.current) {
//       socket.on("receive notification", handleNotification);
//       socket.on("disconnect", handleNotification);
//       notificationListenerAdded.current = true;
//     }

//     return () => {
//       socket.off("receive notification", handleNotification);
//       socket.off("disconnect", handleNotification);
//       notificationListenerAdded.current = false;
//     };
//   }, [chat._id, setUnreadCount, socket]);

  useEffect(() => {
  const handleNotification = (notification) => {
    if (notification.conversationId === chat._id && notification.sender_id !== user?._id) {
      // messageTone.play();
      setUnreadCount(prevUnreadCounts => ({
        ...prevUnreadCounts,
        [chat._id]: prevUnreadCounts[chat._id] ? prevUnreadCounts[chat._id] + 1 : 1,
      }));

      if ('Notification' in window) {
        if (Notification.permission === 'granted') {
          new Notification('New Message', {
            body: `New message from ${getConversationName(user, chat.users)}`,
          });
        } else if (Notification.permission !== 'denied') {
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              new Notification('New Message', {
                body: `New message from ${getConversationName(user, chat.users)}`,
              });
            }
          }).catch(error => {
            console.error('Notification permission request error:', error);
          });
        }
      } else {
        console.warn('This browser does not support notifications.');
      }
    }
  };

  // Only add the listener if it hasn't been added yet
  if (!notificationListenerAdded.current) {
    socket.on("receive notification", handleNotification);
    socket.on("disconnect", handleNotification);
    notificationListenerAdded.current = true;
  }

  // Clean up event listeners when component unmounts or dependencies change
  return () => {
    socket.off("receive notification", handleNotification);
    socket.off("disconnect", handleNotification);
    notificationListenerAdded.current = false;
  };
}, [chat._id, socket, user?._id, setUnreadCount]);

  useEffect(() => {
        socket.on('conversationDeleted', (chat) => {
            dispatch(deleteConversation.fulfilled(chat));
        });

        return () => {
            socket.off('conversationDeleted');
        };
    }, [dispatch]);

  const removeConversation = async (id) => {
  	socket.emit('deleteConversation', id);
    await dispatch(deleteConversation(id));
    // const conversationElement = document.getElementById(id); 
		// if (conversationElement) {
	  //   conversationElement.style.display = 'none';
		// }
	};

	// const removeModule = async (id) => {
	//     await dispatch(deleteModule(id));
	//     dispatch(getModules());
	// };

	const confirmDelete = (id) => {
	    confirmAlert({
	      title: `${chat.isGroup ? chat.name : getConversationName(user, chat.users)}`,
	      message: "Are you sure to do delete this conversation?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeConversation(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

	useEffect(() => {
        if (chat) {
            setLoading(false); // Update loading state when conversations are available
        }
    }, [chat]);

	// useEffect(() => {
  //   let originalTitle = document.title;
  //   let intervalId;

  //   if (unreadCount[chat._id] > 0) {
  //     let count = unreadCount[chat._id];
  //     intervalId = setInterval(() => {
  //       document.title = document.title === originalTitle ? `(${count}) New message from ${getConversationName(user, chat.users)}` : originalTitle;
  //     }, 1000);
  //   } else {
  //     document.title = originalTitle;
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //     document.title = originalTitle;
  //   };
  // }, [unreadCount, chat._id, user]);

  useEffect(() => {
    let originalTitle = document.title;
    let intervalId;

    const totalUnreadCount = Object.values(unreadCount).reduce((acc, count) => acc + count, 0);

    if (totalUnreadCount > 0) {
      intervalId = setInterval(() => {
      	const messageText = totalUnreadCount === 1 ? 'message' : 'messages';
        document.title = document.title === originalTitle ? `🔴 (${totalUnreadCount}) New ${messageText}` : originalTitle;
      }, 1000);
    } else {
      document.title = originalTitle;
    }

    return () => {
      clearInterval(intervalId);
      document.title = originalTitle;
    };
  }, [unreadCount]);

    if (loading) {
        return <ChatLoading />; // Show loading screen if loading is true
    }

    const unreadCountForChat = unreadCount[chat._id] || 0;

    // console.log(defaultUsers);
    let isDisabled;
    const isStudent = user?.roles?.includes('student');
    if (isStudent) {
        isDisabled = !chat.users.some(chatUser =>
        defaultUsers.some(defaultUser => defaultUser.name === chatUser.name)
      );
    } 
    // const isDisabled = userWithChatName === undefined;
    // console.log(isDisabled)

	return (
		<>
			<li id={chat._id} onClick={() => openConversation()} className={`${chat?._id === activeConversation?._id ? "activeChat" : ""} ${isDisabled ? 'disabledUser' : ''}`}>
				{unreadCountForChat > 0 && (<div className="notifyBadge">{unreadCountForChat}</div>)}
				<div className={`chatImg`}>
					<img className={`${online ? "online" : ""}`} src={chat.isGroup ? chat.picture : getConversationPicture(user, chat.users)} alt="Picture"/>
				</div>
				<div className="chatDetail">
					<h6>{chat.isGroup ? chat.name : getConversationName(user, chat.users)}</h6>
					{typing === chat?._id ? (
            <p style={{color : 'green'}}>Typing...</p>
          ) : (
					<p>
					  {chat.latestMessage ? (
					    chat.latestMessage.files && chat.latestMessage.files.length > 0 ? (
					      chat.latestMessage.files.map((file, index) => (
					        <span key={index}>
					          {index > 0 ? ", " : ""}
					          {file.name}
					        </span>
					      ))
					    ) : (
					      chat.latestMessage.message && chat.latestMessage.message.length > 0 ? (
					        chat.latestMessage.message.length > 25 ?
					          `${chat.latestMessage.message.substring(0, 25)}...` :
					          chat.latestMessage.message
					      ) : (
					        ""
					      )
					    )
					  ) : (
					    ""
					  )}
					</p>
					)}
					{/*<small>{moment(chat.latestMessage?.createdAt).fromNow(true)}</small>*/}
				</div>
				<div className="chatDate">
					<small>{chat.latestMessage?.createdAt ? dateHandlerLatestMessage(chat.latestMessage?.createdAt) : " "}</small>
				</div>
				<FilterUser>
				<div className="customDropDownButton">
	        {['end'].map(
	          (direction) => (
	            <DropdownButton
	              key={direction}
	              id={`dropdown-button-drop-${direction}`}
	              drop={direction}
	              title={`...`}
	            >
						    <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(chat._id)}>Delete</Link></li>
	            </DropdownButton>
	          ),
	        )}
	      </div>
	      </FilterUser>
			</li>
		</>
	)
}

const ConversationWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Conversation {...props} socket={socket} />}
  </SocketContext.Consumer>
);

export default ConversationWithContext