import React,{useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import { ChatIcon, CommunityIcon, DotsIcon, StoryIcon } from "../svg";
import { useDispatch,useSelector } from "react-redux";
import Menu from "./Menu";
import CreateGroup from "./CreateGroup";
import {
  selectUser,getUser,
} from "../redux/features/auth/authSlice";
import { FilterUser } from "./hiddenLinks";

export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

export const UserName = () => {
  const { user } = useSelector((state) => state.auth);

  const userName = user?.name || "...";

  return <span className="mb-0 text-capitalize">{shortenText(userName, 14)}</span>;
};

const SidebarHeader = () => {
	
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);

	const [showMenu, setShowMenu] = useState(false);
	const [showCreateGroup, setShowCreateGroup] = useState(false);

	//  useEffect(() => {
  //     dispatch(getUser());
  // }, [dispatch]);

	return (
		<>
			<div className="sidebarHeader">
				<Link to="/"><img src="../../fileImages/ACDAPlogoFinal.png" width='110px' style={{marginLeft : '0px'}}/></Link>
        <ul className="flex align-items-center gap-x-2 5">
            <li>
              <div className="userInfoChat">
                      <img
                        src={user?.picture}
                        alt={user?.name}
                        title={user?.name}
                      />
                </div>
            </li>
            <FilterUser>
            <li onClick={() => setShowCreateGroup(true)}>
              <button className="btn">
                <CommunityIcon className="" />
              </button>
            </li>
            </FilterUser>
            {/*<li>
              <button className="btn">
                <StoryIcon className="" />
              </button>
            </li>
            <li>
              <button className="btn">
                <ChatIcon className="" />
              </button>
            </li>*/}
            <li onClick={() => setShowMenu((prev) => !prev)} style={{position : 'relative'}}>
              <button className={`btn ${showMenu ? "sidebarHeaderMenuBg" : ""}`}>
                <DotsIcon className="" />
              </button>
              {showMenu ? (
                <Menu setShowCreateGroup={setShowCreateGroup}/>
              ) : null}
            </li>
        </ul>
			</div>	
			{/*Create Group*/}
      {showCreateGroup && (
        <CreateGroup setShowCreateGroup={setShowCreateGroup}/>
      )}
		</>
	)
}

export default SidebarHeader