import React,{useState,useCallback} from 'react';
import { useNavigate,Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,RESET,
} from "../redux/features/auth/authSlice";
import { FilterUser, FilterAdmin } from "./hiddenLinks";

export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

export const UserName = () => {
  const { user } = useSelector((state) => state.auth);

  const userName = user?.name || "...";

  return <span className="mb-0 text-capitalize">{shortenText(userName, 14)}</span>;
};

function randomID(len) {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

const Menu = ({setShowCreateGroup}) => {

	const navigate = useNavigate();
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const logoutUser = async () => {
	    dispatch(RESET());
	    await dispatch(logout());
	    navigate("/login");
	    window.location.reload()
	};

	const [value, setValue] = useState(randomID);
	const createMeeting = useCallback(() =>{
		const url = `/room/${value}`;
		// navigate(url);
		window.open(url, '_blank');
		// window.location.reload();
	}, [navigate, value])

	const adminDashboard = useCallback(() =>{
		const url = `/admin/dashboard`;
		navigate(url);
	}, [navigate])

	const allowedEmails = ["idrees.msd@gmail.com", "salman.khan@kbmgroup.co.uk"];

	return (
		<>
			<div className="sidebarHeaderMenu">
				<ul>
				<li><span><b><UserName/></b></span></li>
  {user && allowedEmails.includes(user.email) ? (
    <li onClick={adminDashboard}><span>Dashboard</span></li>
  ) : null}
					<FilterUser>
					<li onClick={() => setShowCreateGroup(true)}><span>New group</span></li>
					<li onClick={() => createMeeting()}><span>Create Meeting</span></li>
					</FilterUser>
					{/*<li><span>Starred messaged</span></li>
					<li><span>Settings</span></li>*/}
					<li onClick={logoutUser} ><span>Logout</span></li>
				</ul>
			</div>
		</>
	)
}

export default Menu