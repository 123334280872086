import React,{useState,useEffect} from 'react';
import Contact from "./Contact";

const SearchResults = ({ searchResults, setSearchResults }) => {

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	return (
		<>
			<div className="searchResultSec" style={{height : windowHeight.winHeight - 150}}>
				
				<div className="searchResultContent chat scrollBar">
					<h5 className="p-2">Contacts</h5>
					<ul>
			          {searchResults &&
			            searchResults.map((user) => (
			              <Contact
			                contact={user}
			                key={user._id}
			                setSearchResults={setSearchResults}
			              />
			            ))}
			        </ul>
				</div>
			</div>
		</>
	)
}

export default SearchResults