import React,{useEffect,useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import ChatHeader from "./ChatHeader";
import ChatMessages from "./ChatMessages";
import ChatActions from "./ChatActions";
import { checkOnlineStatus, getConversationId, getConversationName, selectActiveConversationName } from "../../utils/chat";
import { getConversationMessages, getConversations, renameGroup, addToGroup, removeUserFromGroup, leftGroup } from "../../redux/features/chat/chatSlice";
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "../../redux/features/auth/authSlice";
import FilesPreview from "./previewFiles/FilesPreview";
import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import axios from "axios";
import Contact from "../Contact";

export const shortenText = (text, n) => {
  if (text?.length > n) {
    const shoretenedText = text.substring(0,n).concat("...");
    return shoretenedText;
  }
  return text;
};

const ChatContainer = ({onlineUsers,typing,videoCall,audioCall,isHidden,setIsHidden}) => {

	const dispatch = useDispatch();
	const { activeConversation, conversations, files, messages, isLoading } = useSelector((state) => state.chat);
	// const { user } = useSelector((state) => state.auth);
	// const { token } = user;
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const user = useSelector(selectUser);
	// const conversationName = useSelector(selectActiveConversationName);
	const [searchQuery, setSearchQuery] = useState("");
	const [ViewGroupModal, SetGroupModalShow] = useState(false)
  const handleGroupModalShow = () => { SetGroupModalShow(true) }
  const hanldeGroupModalClose = () => { SetGroupModalShow(false) }
  const [groupChatName, setGroupChatName] = useState("");
  const [searchResults, setSearchResults] = useState([]);

	// useEffect(() => {
	//     dispatch(getLoginStatus());
	//     if (isLoggedIn && user === null) {
	//       dispatch(getUser());
	//     }

	// }, [dispatch, isLoggedIn, user]);
	const values = {
	    // token,
	    chat_id: activeConversation?._id,
	};
	
	useEffect(() => {
	    if (activeConversation?._id) {
	      dispatch(getConversationMessages(values));
	    }
	}, [activeConversation]);

	// console.log('messages',messages)
	// console.log('conversations',conversations)

	const handleAddUser = async (user1) => {
    
    if (activeConversation.users.find((u) => u._id === user1._id)) {
      return toast.error('User Already in group!');
    }

    // if (activeConversation.admin?._id !== user?._id) {
    // 	return toast.error('Only admins can add someone!');
    // }

    try {
    await dispatch(addToGroup({ conversationId: activeConversation._id, userId: user1._id })).unwrap();
    toast.success("User added to the group!");
    setSearchResults([]);
    window.location.reload();
  } catch (error) {
    console.error("Error Occurred:", error);
    toast.error("Error Occurred!");
  }

  };

  const handleRenameGroup = async () => {
    if (!groupChatName) return;

    try {
      await dispatch(renameGroup({ conversationId: activeConversation._id, groupChatName })).unwrap();
      toast.success("Updated the Group Name!");
      setGroupChatName("");
    } catch (error) {
      console.error("Error Occurred:", error);
      toast.error("Error Occurred!");
    }
  };

  const handleSearch = async (e) => {
    if (e.target.value && e.key === "Enter") {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/users?search=${e.target.value}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setSearchResults(data);
      } catch (error) {
        return toast.error("Failed to Load the Search Results");
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleRemoveUser = async (user1) => {
    // if (activeConversation.admin._id !== user._id) {
    //   return toast.error('Only admins can remove someone!');
    // }

    try {
      await dispatch(removeUserFromGroup({ conversationId: activeConversation._id, userId: user1._id }));
      toast.success("User removed from the group!");
    } catch (error) {
      console.error("Error Occurred:", error);
      toast.error("Error Occurred!");
    }
  };

  const handleLeftGroup = async () => {
    try {
	    if (!activeConversation || !activeConversation._id) {
	      throw new Error("Active conversation is null or does not have an _id property");
	    }

	    await dispatch(leftGroup({ conversationId: activeConversation._id, userId: user._id })).unwrap();
	    toast.success("You have left the group!");
	  } catch (error) {
	    console.error("Error Occurred:", error);
	    toast.error("Error Occurred!");
	  }
  };

  const [searchGrpUser, setSearchGrpUser] = useState('');
  const handleSearchGrpUser = (e) => {
    setSearchGrpUser(e.target.value);
  };

  const filteredUsers = activeConversation.users.filter((u) =>
    u.name.toLowerCase().includes(searchGrpUser.toLowerCase()) ||
    u.email.toLowerCase().includes(searchGrpUser.toLowerCase())
  );

	return (
		<>
			<div className={`messagePanel ${isHidden ? 'customBlock' : 'customNone'}`}>
				<ChatHeader online={activeConversation.isGroup ? false : checkOnlineStatus(onlineUsers,user,activeConversation.users)} 
				// callUser={callUser} 
				handleGroupModalShow={handleGroupModalShow}
				searchQuery={searchQuery} setSearchQuery={setSearchQuery}
				videoCall={videoCall} audioCall={audioCall} isHidden={isHidden} setIsHidden={setIsHidden}/> 
				{files.length > 0 ? (
          <FilesPreview />
        ) : (
          <>
            {/*Chat messages*/}
            <ChatMessages typing={typing} searchQuery={searchQuery}/>
            {/* Chat Actions */}
            <ChatActions />
          </>
        )}
			</div>	
			{/*<!-- Modal -->*/}
		    <Modal show={ViewGroupModal}
                    onHide={hanldeGroupModalClose}
                    backdrop="static"
                    keyboard={false}
                    className="updateGroupModal"
                    >
				<h5 className="mb-3">Update Group</h5>
				<h5>{activeConversation.isGroup ? activeConversation.name : getConversationName(user, activeConversation.users)}</h5>
				  <div className="mb-3 renameGroup">
				    <input type="text" className="form-control" placeholder="Group Name" value={groupChatName} onChange={(e) => setGroupChatName(e.target.value)}/>
				    <button className="btn btn-secondary" onClick={handleRenameGroup}>Update Name</button>
				  </div>
				  <div>
				  <input
		        type="text"
		        placeholder="Search Group Members"
		        value={searchGrpUser}
		        onChange={handleSearchGrpUser}
		        className="form-control mb-3"
		      />
				  <div className="d-flex flex-column" style={{overflowY: 'scroll',height: '250px',paddingRight: '10px'}}>
				  	{filteredUsers.map((u) => (
	         		<div className="grpUserList col-12" key={u._id}>
    						<img src={u.picture} />
	    					<div className="grpUserListContent">
	    						<div className="d-flex flex-column align-items-baseline" style={{paddingLeft: '10px'}}>
	    							<p>{u.name}</p>
	    							<small>{u.email}</small>
	    						</div>
	    						<button className="btn btn-danger btn-sm" onClick={() => handleRemoveUser(u)}>Remove</button>
	    					</div>
    					</div>
	        	))}
				  </div>
				  </div>
          <div className="my-3">
				    <input type="text" className="form-control" onKeyDown={(e) => handleSearch(e)} placeholder="Add User"/>
				  </div>
				  <div className="addUserGrp" style={{overflowY: 'scroll',height: searchResults && searchResults.length > 0 ? '250px' : 'auto',paddingRight: '10px'}}>
			          {searchResults &&
			            searchResults.map((user) => (
			              <div className="grpSearchUserList" key={user._id} onClick={() => handleAddUser(user)}>
			    						<img src={user.picture} />
				    					<div style={{lineHeight : '1', marginLeft : '10px'}}>
				    						<p>{user.name}</p>
				    						<small>{user.email}</small>
				    					</div>
			    					</div>
			          ))}
				  </div>
				  <div className="d-flex" style={{marginLeft: 'auto'}}>
	         <button type="button" className="btn btn-secondary" onClick={handleLeftGroup}>Leave Group</button>
	         <button type="button" className="btn btn-secondary" onClick={hanldeGroupModalClose} style={{marginLeft: '10px'}}>Close</button>
	         </div>
			</Modal>
		</>
	)
}

export default ChatContainer