import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/conversation/`;
export const API_URL_MESSAGE = `${BACKEND_URL}/api/message/`;

const getConversations = async(token) =>{
	const response = await axios.get(API_URL + "getconversations", token);
	return response.data;
};

const getConversationById = async(id) =>{
	const response = await axios.get(API_URL + "getConversationById", id);
	return response.data;
};

const open_contact = async(values) =>{
	const response = await axios.post(API_URL + "open_contact", values);
	return response.data;
};

const open_create_conversation = async(values) =>{
	const response = await axios.post(API_URL + "createconversaton", values);
	return response.data;
};

const createGroup = async(values) =>{
	const response = await axios.post(API_URL + 'group', values);
	return response.data;
};

const getConversationMessages = async({chat_id, token}) =>{
	const response = await axios.get(API_URL_MESSAGE +  `${chat_id}` , token);
	return response.data;
};

const sendMessage = async(values) =>{
	const response = await axios.post(API_URL_MESSAGE +  `sendMessage` , values);
	return response.data;
};

// get message by id
const getMessageById = async(id) =>{
	const response = await axios.get(API_URL_MESSAGE + 'getMessageById', id);
	return response.data;
};

// update message
const updateMessage = async(id, messageData) =>{
	const response = await axios.patch(`${API_URL_MESSAGE}${id}`, messageData);
	return response.data;
};

// delete message
const deleteMessage = async(id) =>{
	const response = await axios.delete(API_URL_MESSAGE + id);
	return response.data.message;
};

// delete conversation
const deleteConversation = async(id) =>{
	const response = await axios.delete(API_URL + id);
	return response.data.message;
};

const chatService = {
	getConversations,
	getConversationById,
	open_contact,
	open_create_conversation,
	getConversationMessages,
	sendMessage,
	createGroup,
	getMessageById,
	updateMessage,
	deleteMessage,
	deleteConversation,
}

export default chatService;