import React from 'react';
import DownloadIcon from "../../svg/Download";

const FileOthers = ({ file, type, me, name, size }) => {

// 	function handleDownload(url) {
//   // Initiate download
//   window.open(url, '_blank');
// }

// 	function handleDownloadError(error) {
//   console.error('Error occurred during download:', error);
//   // You can handle the error here, such as showing an error message to the user.
// }
	const downloadFile = () => {
    const fileUrl = file; // Replace with your file URL
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a temporary anchor element
        const link = document.createElement('a');
        // Set the href attribute to the blob URL
        link.href = url;
        // Set the download attribute to force the browser to download the file
        link.setAttribute('download', name); // Replace 'filename.pdf' with your desired file name
        // Hide the anchor element
        link.style.display = 'none';
        // Append the anchor element to the body
        document.body.appendChild(link);
        // Trigger a click on the anchor element
        link.click();
        // Remove the anchor element from the DOM
        document.body.removeChild(link);
        console.log(`${file.name}.${type}`);
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  };
	return (
		<>
			<div className="fileMessageBox">
				<img
		            src={`../../../fileImages/${type}.png`}
		            alt=""
		            className=""
		          />
		        <div className="fileMessageDetail">
		          	<p>
		        	{name}
			        </p>
			        <span>{size} - KB</span> 
		        </div>
		        {/*{me && (*/}
		        	<button onClick={downloadFile}>
				      <DownloadIcon />
				    </button>
		          {/*<a href={file} download >
		            <DownloadIcon />
		          </a>*/}
		        {/*)}*/}
		        {/*{!me && (
		          <a href={file.secure_url} target="_blank" download onClick={() => handleDownload(file.secure_url)} onError={handleDownloadError}>
		            <DownloadIcon />
		          </a>
		        )}*/}
			</div>	
		</>
	)
}

export default FileOthers