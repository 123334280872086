import React,{useRef} from 'react';
import { useDispatch } from "react-redux";
import { DocumentIcon } from "../../svg";
import { addFiles } from "../../redux/features/chat/chatSlice";
import { getFileType } from "../../utils/file";

const DocumentAttachment = () => {

	const dispatch = useDispatch();
  	const inputRef = useRef();

  	// const documentHandler = (e) => {
	//     let files = Array.from(e.target.files);
	//     files.forEach((file) => {
	//       if (
	//         file.type !== "application/pdf" &&
	//         file.type !== "application/x-zip-compressed" &&
	//         file.type !== "text/plain" &&
	//         file.type !== "application/msword" &&
	//         file.type !==
	//           "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
	//         file.type !== "application/vnd.ms-powerpoint" &&
	//         file.type !==
	//           "application/vnd.openxmlformats-officedocument.presentationml.presentation" &&
	//         file.type !== "application/vnd.ms-excel" &&
	//         file.type !==
	//           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
	//         file.type !== "application/vnd.rar" &&
	//         file.type !== "application/zip" &&
	//         file.type !== "audio/mpeg" &&
	//         file.type !== "audio/wav"
	//       ) {
	//         files = files.filter((item) => item.name !== file.name);
	//         console.log(file.type);
	//         return;
	//       } else if (file.size > 1024 * 1024 * 10) {
	//         files = files.filter((item) => item.name !== file.name);
	//         return;
	//       } else {
	//         const reader = new FileReader();
	//         reader.readAsDataURL(file);
	//         reader.onload = (e) => {
	//           dispatch(
	//             addFiles({
	//               file: file,
	//               type: getFileType(file.type),
	//             })
	//           );
	//         };
	//       }
	//     });
	// };

	const documentHandler = (e) => {
    let files = Array.from(e.target.files);
    files.forEach((file) => {
        if (file.size > 1024 * 1024 * 10) {
            files = files.filter((item) => item.name !== file.name);
            return;
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                dispatch(
                    addFiles({
                        file: file,
                        type: getFileType(file.type),
                    })
                );
            };
        }
    });
};

	return (
		<>
			<li>
		      <button
		        type="button"
		        className="btn"
		        onClick={() => inputRef.current.click()}
		        style={{background: '#5F66CD'}}
		      >
		        <DocumentIcon />
		      </button>
		      <input
		        type="file"
		        hidden
		        multiple
		        ref={inputRef}
		        accept="*/*"
		        onChange={documentHandler}
		      />
		    </li>
		</>
	)
}

export default DocumentAttachment