import React,{useState,useEffect} from 'react';
import { useSelector } from "react-redux";

const FileViewer = ({ activeIndex }) => {
	const { files } = useSelector((state) => state.chat);

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])
	return (
		<>
			<div className="filePreviewPanel" style={{height : windowHeight.winHeight - 240}}>
			{
				files[activeIndex]?.type === "IMAGE" ? (
					<img
			            src={files[activeIndex].fileData}
			            alt=""
			            className=""
			        />
				) : files[activeIndex]?.type === "VIDEO" ? (
		          <video
		            src={files[activeIndex].fileData}
		            controls
		            className=""
		          ></video>
		        ) : (
					<div className="fileIcon">
						<img src={`../../../fileImages/${files[activeIndex]?.type}.png`} alt={files[activeIndex]?.type}/>
						<p>No preview available</p>
						<span>{files[activeIndex]?.file?.size} KB - {files[activeIndex]?.type}</span>
					</div>
				)
			}
			</div>
		</>
	)
}

export default FileViewer