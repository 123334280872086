import React from 'react';

const UnderlineInput = ({ name, setName }) => {
	return (
		<>
			<div className="createGroupInput">
				<input
			        type="text"
			        placeholder="Enter Group Name"
			        value={name}
			        onChange={(e) => setName(e.target.value)}
			        className="form-control"
			    />
			</div>	
		</>
	)
}

export default UnderlineInput