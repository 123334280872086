import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/users/`;

// Validate email
export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

// register user
const register = async(userData) =>{
	const response = await axios.post(API_URL + "register", userData);
	return response.data;
};

// login user
const login = async(userData) =>{
	const response = await axios.post(API_URL + "login", userData);
	return response.data;
};

// logout user
const logout = async() =>{
	const response = await axios.get(API_URL + "logout");
	return response.data.message;
};

// get login status
const getLoginStatus = async() =>{
	const response = await axios.get(API_URL + "loginStatus");
	return response.data;
};

// get user
const getUser = async() =>{
	const response = await axios.get(API_URL + "getUser");
	return response.data;
};

// getByDefaultUsers
const getByDefaultUsers = async() =>{
	const response = await axios.get(API_URL + "getByDefaultUsers");
	return response.data;
};

// getCalendarData
const getCalendarData = async() =>{
	const response = await axios.get(API_URL + "getCalendarData");
	return response.data;
};
// const getTrainers = async() =>{
// 	const response = await axios.get(API_URL + "gettrainers");
// 	return response.data;
// };
const createComplaint = async() =>{
	const response = await axios.get(API_URL + "createComplaint");
	return response.data;
};


const authService = {
	register,
	login,
	getLoginStatus,
	getUser,
	logout,
	getByDefaultUsers,
	getCalendarData,
	// getTrainers,
	createComplaint,
}

export default authService;