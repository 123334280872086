import React,{useState} from 'react';
import { useSelector } from "react-redux";
import SocketContext from "../../context/SocketContext";

const Input = ({message, setMessage, textRef, socket}) => {
	const { activeConversation } = useSelector((state) => state.chat);

	const [typing, setTyping] = useState(false);

	const onChangeHandler = (e) => {
	    setMessage(e.target.value);
	    if (!typing) {
	      setTyping(true);
	      socket.emit("typing", activeConversation._id);
	    }
	    let lastTypingTime = new Date().getTime();
	    let timer = 1000;
	    setTimeout(() => {
	      let timeNow = new Date().getTime();
	      let timeDiff = timeNow - lastTypingTime;
	      if (timeDiff >= timer && typing) {
	        socket.emit("stop typing", activeConversation._id);
	        setTyping(false);
	      }
	    }, timer);
	};
	// console.log('message',message)

	return (
		<>
			<div className="inputMessage">
				<input
			        type="text"
			        className="form-control"
			        placeholder="Type a message"
			        value={message}
			        onChange={onChangeHandler}
			        ref={textRef}
			    />
			</div>
		</>
	)
}

const InputWithSocket = (props) => (
  <SocketContext.Consumer>
    {(socket) => <Input {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default InputWithSocket;