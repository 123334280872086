import React,{useRef} from 'react';
import { useDispatch } from "react-redux";
import { PhotoIcon } from "../../svg";
import { addFiles } from "../../redux/features/chat/chatSlice";
import { getFileType } from "../../utils/file";

const PhotoAttachment = () => {

	const dispatch = useDispatch();
	const inputRef = useRef(null);
	// const imageHandler = (e) => {
	//     let files = Array.from(e.target.files);
	//     files.forEach((file) => {
	//       if (
	//         file.type !== "image/png" &&
	//         file.type !== "image/jpeg" &&
	//         file.type !== "image/gif" &&
	//         file.type !== "image/webp" &&
	//         file.type !== "video/mp4" &&
	//         file.type !== "video/mpeg" &&
	//         file.type !== "application/x-troff-msvideo" &&
    // 		file.type !== "video/avi" &&
    // 		file.type !== "video/msvideo" &&
    // 		file.type !== "video/x-msvideo" &&
	//         file.type !== "image/webm" &&
	//         file.type !== "image/jpg"
	//       ) {
	//         files = files.filter((item) => item.name !== file.name);
	//         return;
	//       } else if (file.size > 1024 * 1024 * 10) {
	//         files = files.filter((item) => item.name !== file.name);
	//         return;
	//       } else {
	//         const reader = new FileReader();
	//         reader.readAsDataURL(file);
	//         reader.onload = (e) => {
	//           dispatch(
	//             addFiles({
	//               file: file,
	//               fileData: e.target.result,
	//               type: getFileType(file.type),
	//             })
	//           );
	//         };
	//       }
	//     });
	// };

	const imageHandler = (e) => {
	    let files = Array.from(e.target.files);
	    files.forEach((file) => {
	        if (file.size > 1024 * 1024 * 10) {
	            files = files.filter((item) => item.name !== file.name);
	            return;
	        } else {
	            const reader = new FileReader();
	            reader.readAsDataURL(file);
	            reader.onload = (e) => {
	                dispatch(
	                    addFiles({
	                        file: file,
	                        fileData: e.target.result,
	                        type: getFileType(file.type),
	                    })
	                );
	            };
	        }
	    });
	};

	return (
		<>
			<li>
		      <button
		        type="button"
		        className="btn"
		        onClick={() => inputRef.current.click()}
		        style={{background: '#BF59CF'}}
		      >
		        <PhotoIcon />
		      </button>
		      <input
		        type="file"
		        hidden
		        multiple
		        ref={inputRef}
		        accept="*/*"
		        // accept="application/*,text/plain,image/png,image/jpeg,image/gif,image/webp,video/mp4,video/mpeg,video/mpg,video/avi,"
		        onChange={imageHandler}
		      />
		    </li>
		</>
	)
}

export default PhotoAttachment