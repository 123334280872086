import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { dateHandler } from "../../utils/date";
import {
		getConversationMessages,
  	deleteMessage,
} 	from "../../redux/features/chat/chatSlice";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FilterUser } from "../hiddenLinks";

export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

const Message = ({ socket, message, me, confirmDelete, createHyperlinks }) => {

	const formattedDate = message.seen 
    ? `Read on ${new Date(message.updatedAt).toLocaleString('en-US', { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit' 
      })}` 
    : '-';

	return (
		<>
			<div className={`d-flex ${me ? "ml-auto justify-content-end " : ""}`}>
			{!me && message.conversation?.isGroup && (
	          <div className="senderImg d-flex flex-column">
	            <img
	              src={message.sender.picture}
	              alt={message.sender.name}
	              title={message.sender.name}
	              className="w-8 h-8 rounded-full"
	            />
	          </div>
	        )}
				<div className={`position-relative textBox ${me ? "messageBg1" : "messageBg2"}`} id={message._id} title={formattedDate}>
				{!me && message.conversation?.isGroup && (<small className="grpUserName">{shortenText(message.sender.name, 10)}</small>)}
				<div className={`d-flex ${me ? "flex-row-reverse" : "flex-row"} justify-space-between`}>
					<div className="" style={{width : '100%'}}>
						<p className="m-0 text-white">{message.message}</p>
						{/*<span className="text-white">{moment(message.createdAt).format("HH:mm")}</span>*/}
						<span className="text-white">{message?.createdAt ? dateHandler(message?.createdAt) : " "}</span>
						{/*{!me ? (
				            <span>
				              <TraingleIcon className="" />
				            </span>
				          ) : null}*/}
            
			            <div className="message-info">
			                {me && (
			                    <span className="message-status">
			                        {message.seen ? '✓✓' : '✓'}
			                    </span>
			                )}
			            </div>
					</div>
					<FilterUser>
					{
						me ?
						<div className="customDropDownButton">
			        {['start'].map(
			          (direction) => (
			            <DropdownButton
			              key={direction}
			              id={`dropdown-button-drop-${direction}`}
			              drop={direction}
			              title={`...`}
			            >
			              {/*<li><Link className="dropdown-item" onClick={() => getModule(message._id)}>Edit</Link></li>*/}
								    <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(message._id)}>Delete</Link></li>
			            </DropdownButton>
			          ),
			        )}
			      </div>
			      :
			      <div className="customDropDownButton">
			        {['end'].map(
			          (direction) => (
			            <DropdownButton
			              key={direction}
			              id={`dropdown-button-drop-${direction}`}
			              drop={direction}
			              title={`...`}
			            >
			              {/*<li><Link className="dropdown-item" onClick={() => getModule(message._id)}>Edit</Link></li>*/}
								    <li><Link className="dropdown-item" to="#" onClick={() => confirmDelete(message._id)}>Delete</Link></li>
			            </DropdownButton>
			          ),
			        )}
			      </div> 
					}
					</FilterUser>
				</div>
				</div>
			</div>
		</>
	)
}

export default Message
