import React from 'react';
import { CloseIcon } from "../../../svg";
import { useDispatch, useSelector } from "react-redux";
import { clearFiles } from "../../../redux/features/chat/chatSlice";

const FilesPreviewHeader = ({activeIndex}) => {
	const dispatch = useDispatch();
  	const { files } = useSelector((state) => state.chat);
  	const clearFilesHandler = () => {
    	dispatch(clearFiles());
  	};

	return (
		<>
			<div className="filePreviewHeader">
				<div className="closeFile" onClick={() => clearFilesHandler()}>
					<CloseIcon />	
				</div>
				<p className="fileName">
		          {files[activeIndex]?.file?.name}
		        </p>
			</div>	
		</>
	)
}

export default FilesPreviewHeader