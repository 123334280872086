import React,{useState,useEffect} from 'react';
import { Logo } from "../../svg";

const KbmChatHome = () => {

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	return (
		<>
			<div className="KbmChatHomeSec" style={{height : windowHeight.winHeight}}>
				<Logo/>
				<h2 className="mt-4">KBM Chat Web</h2>
				<p>Send and receive messages</p>
			</div>
		</>
	)
}

export default KbmChatHome