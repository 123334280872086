import React from 'react';
import RegisterForm from "../components/auth/RegisterForm";

const Register = () => {
	return (
		<>
			<div className="">
				<RegisterForm />
			</div>	
		</>
	)
}

export default Register