import React from 'react';
import moment from "moment";
import { BeatLoader } from "react-spinners";

const Typing = ({ message }) => {
	return (
		<>
			<div className={`typingLoader`}>
				<div className={`position-relative`}>
					{/*<p className="m-0 text-white">Typing</p>*/}
					<BeatLoader color="#081a3e" size={10} />
					{/*{!me ? (
			            <span>
			              <TraingleIcon className="" />
			            </span>
			          ) : null}*/}
				</div>
			</div>
		</>
	)
}

export default Typing