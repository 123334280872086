import React,{ useState,useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FilterIcon, ReturnIcon, SearchIcon } from "../svg";
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "../redux/features/auth/authSlice";

const Search = ({searchLength, setSearchResults}) => {

	const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
	const user = useSelector(selectUser);
	// useEffect(() => {
	//     dispatch(getLoginStatus());
	//     if (isLoggedIn && user === null) {
	//       dispatch(getUser());
	//     }

	// }, [dispatch, isLoggedIn, user]);

	const [show, setShow] = useState(false);

	const handleSearch = async (e) => {
    if (e.target.value && e.key === "Enter") {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/users?search=${e.target.value}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setSearchResults(data);
      } catch (error) {
        return toast.error("Failed to Load the Search Results");
      }
    } else {
      setSearchResults([]);
    }
  };

	return (
		<>
			
			<div className="searchSidebar">
				{show || searchLength > 0 ? (
	              <span className="rotateIcon"
	                onClick={() => setSearchResults([])}
	              >
	                <ReturnIcon />
	              </span>
	            ) : (
	              <span>
	                <SearchIcon />
	              </span>
	            )}
	            <input
	              type="text"
	              placeholder="Start a new chat with name or email"
	              className="input"
	              onFocus={() => setShow(true)}
	              onBlur={() => searchLength == 0 && setShow(false)}
	              onKeyDown={(e) => handleSearch(e)}
	            />
	            <button className="btn">
		            <FilterIcon />
		        </button>
			</div>

		</>
	)
}

export default Search