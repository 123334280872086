import React,{useState, useEffect, useRef} from 'react';
import { useSelector,useDispatch } from "react-redux";
import Message from './Message';
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import Typing from './Typing';
import FileMessage from "./FileMessage";
import { msgDateHandler } from "../../utils/date";
import {
  	deleteMessage,
  	updateMessagesAndConversations,
  	deleteFile,
} 	from "../../redux/features/chat/chatSlice";
import SocketContext from "../../context/SocketContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const ChatMessages = ({ typing,searchQuery,socket }) => {

	const { messages, activeConversation } = useSelector((state) => state.chat);
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const endRef = useRef();
	const firstHighlightRef = useRef();
    const [highlightedMessages, setHighlightedMessages] = useState([]);
    const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0);
     const [loading, setLoading] = useState(true);
    // const [filteredMessages, setFilteredMessages] = useState(messages);

    //  useEffect(() => {
    //     // Simulating message fetch
    //     const fetchMessages = async () => {
    //         setLoading(true);
    //         // Fetch messages from your backend here (if applicable)
    //         await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate loading
    //         setLoading(false);
    //         scrollToBottom();
    //     };

    //     fetchMessages();
    // }, [activeConversation,messages,typing]);

	useEffect(() => {
	    scrollToBottom();
	}, [messages, typing]);

	// useEffect(() => {
    //     setFilteredMessages(
    //         messages.filter(message => 
    //             message.message.toLowerCase().includes(searchQuery.toLowerCase())
    //         )
    //     );
    // }, [searchQuery, messages]);

    // useEffect(() => {
    //     if (searchQuery) {
    //         if (firstHighlightRef.current) {
    //             firstHighlightRef.current.scrollIntoView({ behavior: "smooth" });
    //         }
    //     } else {
    //         scrollToBottom();
    //     }
    // }, [searchQuery]);

    useEffect(() => {
        if (searchQuery) {
            const highlightRefs = [];
            messages.forEach((message, index) => {
                if (message.message.toLowerCase().includes(searchQuery.toLowerCase())) {
                    highlightRefs.push(index);
                }
            });
            setHighlightedMessages(highlightRefs);
            setCurrentHighlightIndex(0);
        } else {
            setHighlightedMessages([]);
            scrollToBottom();
        }
    }, [searchQuery, messages]);

    useEffect(() => {
        if (highlightedMessages.length > 0 && currentHighlightIndex < highlightedMessages.length) {
            const messageIndex = highlightedMessages[currentHighlightIndex];
            const messageElement = document.getElementById(messages[messageIndex]._id);
            if (messageElement) {
                messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [currentHighlightIndex, highlightedMessages, messages]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && searchQuery) {
                setCurrentHighlightIndex((prevIndex) => (prevIndex + 1) % highlightedMessages.length);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [searchQuery, highlightedMessages]);

    const escapeRegExp = (string) => {
	    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	};

    const linkify = (text) => {
        const urlPattern = /(https?:\/\/[^\s]+)/g;
        const phonePattern = /(\b\d{3}[-.]?\d{3}[-.]?\d{4}\b)/g;

        return text.split(/(\s+)/).map((part, index) => {
            if (urlPattern.test(part)) {
                return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
            } else if (phonePattern.test(part)) {
                return <a key={index} href={`tel:${part}`}>{part}</a>;
            }
            return part;
        });
    };

	const highlightSearchTerm = (text, term) => {
        if (!term) return linkify(text);

        const escapedTerm = escapeRegExp(term);
        const parts = text.split(new RegExp(`(${escapedTerm})`, 'gi'));
        let firstHighlightFound = false;

        return (
            <>
                {parts.map((part, index) => {
                    const linkedPart = linkify(part);
                    if (part.toLowerCase() === term.toLowerCase()) {
                        if (!firstHighlightFound) {
                            firstHighlightFound = true;
                            return (
                                <span key={index} ref={firstHighlightRef} style={{ backgroundColor: 'darkgoldenrod', display: 'inline-block' }}>
                                    {linkedPart}
                                </span>
                            );
                        } else {
                            return (
                                <span key={index} style={{ backgroundColor: 'darkgoldenrod', display: 'inline-block' }}>
                                    {linkedPart}
                                </span>
                            );
                        }
                    }
                    return linkedPart;
                })}
            </>
        );
    };

	const scrollToBottom = () => {
	    endRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	const currentTime = new Date().toLocaleTimeString();

	useEffect(() => {
        socket.on('messageDeleted', (messageId) => {
            dispatch(deleteMessage.fulfilled(messageId));
        });

        return () => {
            socket.off('messageDeleted');
        };
    }, [dispatch]);

    useEffect(() => {
    const handleFileDeleted = ({ messageId, fileUrl }) => {
        dispatch(deleteFile.fulfilled({ messageId, fileUrl }));
    };

    socket.on('fileDeleted', handleFileDeleted);

    return () => {
        socket.off('fileDeleted', handleFileDeleted);
    };
}, [dispatch]);

	const removeMessage = async (id) => {
		socket.emit('deleteMessage', id);
        await dispatch(deleteMessage(id));
        // const messageElement = document.getElementById(id);
        // if (messageElement) {
        //     messageElement.style.display = 'none';
        // }
    };

    const removeFile = async (messageId, fileUrl) => {
	    socket.emit('deleteFile', { messageId, fileUrl });
	    await dispatch(deleteFile({ messageId, fileUrl }));
	};

    const confirmDelete = (id) => {
	    confirmAlert({
	      title: `Message`,
	      message: "Are you sure to do delete this message?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeMessage(id),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

	const confirmDeleteFile = (messageId, fileUrl) => {
	    confirmAlert({
	      title: `Message`,
	      message: "Are you sure to do delete this file?",
	      buttons: [
	        {
	          label: "Delete",
	          onClick: () => removeFile(messageId, fileUrl),
	        },
	        {
	          label: "Cancel",
	          // onClick: () => alert("Click No"),
	        },
	      ],
	    });
	};

	return (
		<>
			<div className="messageBg">
            <div className="messageScroll" style={{ height: windowHeight.winHeight - 100 }}>
            {/*loading ? (
                <div className="customSpinner" style={{ height: windowHeight.winHeight - 150 }}>
                    <ClipLoader />
                </div>
                ) : (*/}
             {

                messages && messages.map((message, index) => {
                    const previousMessage = messages[index - 1];
                    const showDateSeparator = !previousMessage || msgDateHandler(previousMessage.createdAt) !== msgDateHandler(message.createdAt);

                    return (
                        <div key={message._id}>
                            {showDateSeparator && (
                                <div className="date-separator">
                                    {msgDateHandler(message.createdAt)}
                                </div>
                            )}
                            <div className="position-relative" style={{ margin: '10px 0' }} id={message._id}>
                                {message.files.length > 0
                                    ? message.files.map((file, i) => (
                                        <FileMessage
                                            FileMessage={file}
                                            message={message}
                                            key={i}
                                            confirmDelete={() => confirmDeleteFile(message._id, file.file)}
                                            me={user?._id === message.sender?._id}
                                        />
                                    ))
                                    : null}
                                {message.message.length > 0 ? (
                                    <Message
                                        message={{ ...message, message: highlightSearchTerm(message.message, searchQuery) }}
                                        key={message._id}
                                        confirmDelete={confirmDelete}
                                        me={user?._id === message.sender?._id}
                                    />
                                ) : null}
                            </div>
                        </div>
                        )
                    })}
                {typing === activeConversation._id ? <Typing /> : null}
                <div className="mt-2" ref={endRef}></div>
            </div>
        </div>
		</>
	)
}


const ChatMessagesWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <ChatMessages {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default ChatMessagesWithContext;