import React,{ useState } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const FileImageVideo = ({ url, type, name }) => {

	return (
		<>
			<div className="">
				{type === "IMAGE" ? (
					<>
					{/*<a href={url} target="_blank">
			        	<img src={url} alt={name}/>
					</a>*/}
					<PhotoProvider>
          <PhotoView src={url}>
            <img 
              src={url} 
              alt={name} 
              style={{ cursor: 'pointer', maxWidth: '100%' }} 
            />
          </PhotoView>
        </PhotoProvider>
					</>
			      ) : (
			        <video src={url} controls></video>
			      )}
			</div>
		</>
	)
}

export default FileImageVideo