import React,{useState,useEffect,useMemo} from 'react';
import { useDispatch, useSelector } from "react-redux";
import DashboardHeader from './DashboardHeader.js';
import DashboardSideBar from './DashboardSideBar.js';
import SelectedUserChat from './SelectedUserChat.js';
import axios from "axios";
import { toast } from "react-toastify";
import {MaterialReactTable} from 'material-react-table';
import { Link } from 'react-router-dom';
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEdit } from "react-icons/ai";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ClipLoader from "react-spinners/ClipLoader";

const Feedback = () => {

  useRedirectLoggedOutUser('/login')  

	const [trainers, setTrainers] = useState([]);
	const [feedbacks, setFeedbacks] = useState([]);
	const { user } = useSelector((state) => state.auth);
  const [feedbackusers, setfeedbackusers] = useState([]);
	const [selectedChat, setSelectedChat] = useState(null);
	const [activeChatId, setActiveChatId] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);

	const handleChatSelect = async (chatId) => {
		setActiveChatId(chatId);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/message/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSelectedChat(data);
    } catch (error) {
      toast.error('Failed to Load Conversation');
    }
  };

	// Function to fetch feedbacks
   const fetchFeedbacks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/feedback`);
      setFeedbacks(response.data);
      const userIds = [...new Set(response.data.map(fb => fb.feedbackLogger))];
      if (userIds.length > 0) {
        const usersResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users`, {
          params: { ids: userIds }
        });
        setfeedbackusers(usersResponse.data);
      }
    } catch (err) {
      console.error("Error fetching feedbacks");
    }
  };

  useEffect(() => {
    fetchFeedbacks();
  }, []);


	useEffect(() => {
        const fetchTrainers = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/users/getTrainers`, {
                    params: {
                        // formData or other query parameters
                    }
                });
                setTrainers(response.data);
                //console.log('Trainers data:', response.data);
            } catch (error) {
                // setError('Error fetching trainers data');
                console.error('Error fetching trainers data:', error);
            }
        };

        fetchTrainers();
    }, []);

    const handleStatusChange = async (feedbackId, newStatus) => {

    	setFeedbacks(feedbacks.map(feedback => 
            feedback._id === feedbackId ? { ...feedback, status: newStatus } : feedback
        ));
        
		try {
			await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/feedback/${feedbackId}`, {
				status: newStatus
			});
			toast.success('Status updated successfully');
		} catch (error) {
			console.error('Error updating status:', error);
			toast.error('Failed to update status');
		}
	};

	const handleTrainerChange = async (feedbackId, newTrainerId) => {

		setFeedbacks(feedbacks.map(feedback => 
            feedback._id === feedbackId ? { ...feedback, trainerId: newTrainerId } : feedback
        ));

		try {
			await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/users/feedback/${feedbackId}`, {
				trainerId: newTrainerId
			});
			toast.success('Trainer assigned successfully');
		} catch (error) {
			console.error('Error assigning trainer:', error);
			toast.error('Failed to assign trainer');
		}
	};

   // Map feedbackLogger IDs to user names
  const feedbacksWithUserNames = feedbacks.map(feedback => {
    const user = feedbackusers.find(user => user._id === feedback.feedbackLogger);
    return { ...feedback, feedbackLoggerName: user ? user.name : <ClipLoader/> };
  });


const columns = useMemo(
    () => [
      {
      accessorFn: (row) => row.feedbackLoggerName, // Use feedbackLoggerName
      id: 'feedbackLoggerName',
      header: 'Feedback Logger',
      Header: () => <span>Feedback Logger</span>,
    },
      {
        accessorFn: (row) => row.expectations, //alternate way
        id: 'expectations', //id required if you use accessorFn instead of accessorKey
        header: 'Did the training meet your expectations, and can you apply the knowledge learned?',
        Header: () => <span>Did the training meet your expectations, and can you apply the knowledge learned?</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.objectives, //alternate way
        id: 'objectives', //id required if you use accessorFn instead of accessorKey
        header: 'Were the objectives clear and the content organized?',
        Header: () => <span>Were the objectives clear and the content organized?</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.knowledgeable, //alternate way
        id: 'knowledgeable', //id required if you use accessorFn instead of accessorKey
        header: 'Were the materials useful, and was the trainer knowledgeable?',
        Header: () => <span>Were the materials useful, and was the trainer knowledgeable?</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.encouraged, //alternate way
        id: 'encouraged', //id required if you use accessorFn instead of accessorKey
        header: 'Was the instruction quality good, and was participation encouraged?',
        Header: () => <span>Was the instruction quality good, and was participation encouraged?</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.discussion, //alternate way
        id: 'discussion', //id required if you use accessorFn instead of accessorKey
        header: 'Was there enough time for questions and discussion?',
        Header: () => <span>Was there enough time for questions and discussion?</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.rate, //alternate way
        id: 'rate', //id required if you use accessorFn instead of accessorKey
        header: 'Overall Rating',
        Header: () => <span>Overall Rating</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.feedbackType, //alternate way
        id: 'feedbackType', //id required if you use accessorFn instead of accessorKey
        header: 'Type',
        Header: () => <span>Type</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span className="d-flex align-items-center">
        	<select className="form-select" value={row.status || ""} onChange={(e) => handleStatusChange(row._id, e.target.value)}>
			  <option value="">Select Status</option>
			  <option value="Pending">Pending</option>
		 	  <option value="Active">Active</option>
			  <option value="In-process">In-process</option>
			  <option value="Done">Done</option>
			</select>
        </span>, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span className="d-flex align-items-center">
        	<select className="form-select" value={row.trainerId || ""} onChange={(e) => handleTrainerChange(row._id, e.target.value)}>
        	<option value="">Select Trainer</option>
			  {trainers && trainers.map((user) => (
					<option key={user._id} value={user._id}>
						{user.name}
					</option>
				))}
			</select>
        </span>, //alternate way
        id: 'trainer', //id required if you use accessorFn instead of accessorKey
        header: 'Trainer',
        Header: () => <span>Trainer</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span>
                            <FaTrashAlt
                              size={18}
                              cursor="pointer"
                              color="rgb(0,0,0,.8)"
                              
                            />
                            &nbsp;
                            <Link to={`/`}>
	                            <AiFillEdit
	                              size={20}
	                              cursor="pointer"
	                              color="rgb(0,0,0,.8)"
	                            />
                            </Link>
                          </span>, //alternate way
        id: 'action', //id required if you use accessorFn instead of accessorKey
        header: 'Action',
        Header: () => <span>Action</span>, //optional custom header render
      },
    ],
    [trainers, feedbacksWithUserNames]
  );

const column = useMemo(
    () => [
      {
      accessorFn: (row) => row.feedbackLoggerName, // Use feedbackLoggerName
      id: 'feedbackLoggerName',
      header: 'Feedback Logger',
      Header: () => <span>Feedback Logger</span>,
    },
      {
        accessorFn: (row) => row.sessionLocation, //alternate way
        id: 'sessionLocation', //id required if you use accessorFn instead of accessorKey
        header: 'Location',
        Header: () => <span>Location</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.course, //alternate way
        id: 'course', //id required if you use accessorFn instead of accessorKey
        header: 'Course',
        Header: () => <span>Course</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.client, //alternate way
        id: 'client', //id required if you use accessorFn instead of accessorKey
        header: 'Client',
        Header: () => <span>Client</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.topic, //alternate way
        id: 'topic', //id required if you use accessorFn instead of accessorKey
        header: 'Topic',
        Header: () => <span>Topic</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.software, //alternate way
        id: 'software', //id required if you use accessorFn instead of accessorKey
        header: 'Software',
        Header: () => <span>Software</span>, //optional custom header render
      },
      {
        accessorFn: (row) => row.description, //alternate way
        id: 'description', //id required if you use accessorFn instead of accessorKey
        header: 'Description',
        Header: () => <span>Description</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span className="d-flex align-items-center">
        	<select className="form-select" value={row.status || ""} onChange={(e) => handleStatusChange(row._id, e.target.value)}>
			  <option value="">Select Status</option>
			  <option value="Pending">Pending</option>
		 	  <option value="Active">Active</option>
			  <option value="In-process">In-process</option>
			  <option value="Done">Done</option>
			</select>
        </span>, //alternate way
        id: 'status', //id required if you use accessorFn instead of accessorKey
        header: 'Status',
        Header: () => <span>Status</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span className="d-flex align-items-center">
        	<select className="form-select" value={row.trainerId || ""} onChange={(e) => handleTrainerChange(row._id, e.target.value)}>
        	<option value="">Select Trainer</option>
			  {trainers && trainers.map((user) => (
					<option key={user._id} value={user._id}>
						{user.name}
					</option>
				))}
			</select>
        </span>, //alternate way
        id: 'trainer', //id required if you use accessorFn instead of accessorKey
        header: 'Trainer',
        Header: () => <span>Trainer</span>, //optional custom header render
      },
      {
        accessorFn: (row) => <span>
                            <FaTrashAlt
                              size={18}
                              cursor="pointer"
                              color="rgb(0,0,0,.8)"
                              
                            />
                            &nbsp;
                            <Link to={`/`}>
	                            <AiFillEdit
	                              size={20}
	                              cursor="pointer"
	                              color="rgb(0,0,0,.8)"
	                            />
                            </Link>
                          </span>, //alternate way
        id: 'action', //id required if you use accessorFn instead of accessorKey
        header: 'Action',
        Header: () => <span>Action</span>, //optional custom header render
      },
    ],
    [trainers, feedbacksWithUserNames]
  );

// Filter feedbacks by feedbackType
  const angryFeedbacks = useMemo(() => feedbacksWithUserNames.filter(fb => fb.feedbackType === 'angry'), [feedbacksWithUserNames]);
  const happyNeutralFeedbacks = useMemo(() => feedbacksWithUserNames.filter(fb => ['happy', 'neutral'].includes(fb.feedbackType)), [feedbacksWithUserNames]);
  
	return (
		<>
			<DashboardHeader />
			<div className="mainContainer">
				<DashboardSideBar handleChatSelect={handleChatSelect} activeChatId={activeChatId} selectedUser={selectedUser} setSelectedUser={setSelectedUser}/>
				<div className="dashboardContainer">
				{selectedChat ? (
		            <SelectedUserChat selectedChat={selectedChat} selectedUser={selectedUser}/>
		        ) : (
		            <div className="feedbackSec">
		            	<Tabs
					      defaultActiveKey="angry"
					      id="uncontrolled-tab-example"
					      className="mb-3"
					    >
					      <Tab eventKey="angry" title="Angry">
					        <MaterialReactTable columns={column} data={angryFeedbacks} enableRowNumbers rowNumberMode="original"/>
					      </Tab>
					      <Tab eventKey="happy&neutral" title="Happy & Neutral">
					        <MaterialReactTable columns={columns} data={happyNeutralFeedbacks} enableRowNumbers rowNumberMode="original"/>
					      </Tab>
					    </Tabs>
					</div>
		        )}
				
				</div>
			</div>
		</>
	)
}

export default Feedback