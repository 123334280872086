import React from 'react';
import Select from "react-select";

const MultipleSelect = ({selectedUsers,setSelectedUsers,searchResults,handleSearchGroup,}) => {
	return (
		<>
			<div className="createGroupMultiUser">
				<Select
			        options={searchResults}
			        onChange={setSelectedUsers}
			        onKeyDown={(e) => handleSearchGroup(e)}
			        placeholder="Search, select users"
			        isMulti
			        formatOptionLabel={(user) => (
			          <div className="userGroup">
			            <img
			              src={user.picture}
			              alt=""
			            />
			            <span>{user.label}</span>
			          </div>
			        )}
			        styles={{
			          control: (baseStyles, state) => ({
			            ...baseStyles,
			            border: "none",
			            borderColor: "transparent",
			            background: "transparent",
			          }),
			        }}
			    />
			</div>
		</>
	)
}

export default MultipleSelect