import React from 'react';
import { AttachmentIcon } from "../../svg";
import AttachmentMenu from "./AttachmentMenu";

const Attachments = ({showAttachments,setShowAttachments,setShowPicker,}) => {
	return (
		<>
			<li className="position-relative">
				<button className="btn" type="button" onClick={() => {setShowPicker(false);setShowAttachments((prev) => !prev);}}>
					<AttachmentIcon />
				</button>
				{showAttachments ? <AttachmentMenu /> : null}
			</li>
		</>
	)
}

export default Attachments