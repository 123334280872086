import moment from "moment";

// export const dateHandler = (date) => {
//   let now = moment();
//   let momentDate = moment(date);
//   let time = momentDate.fromNow(true);
//   let dateByHourAndMin = momentDate.format("HH:mm");
//   const getDay = () => {
//     let days = time.split(" ")[0];
//     if (Number(days) < 8) {
//       return now.subtract(Number(days), "days").format("dddd");
//     } else {
//       return momentDate.format("DD/MM/YYYY");
//     }
//   };
//   if (time === "a few seconds") {
//     return "Now";
//   }
//   if (time.search("minute") !== -1) {
//     let mins = time.split(" ")[0];
//     if (mins === "a") {
//       return "1 min";
//     } else {
//       return `${mins} min`;
//     }
//   }
//   if (time.search("hour") !== -1) {
//     return dateByHourAndMin;
//   }
//   if (time === "a day") {
//     return "Yesterday";
//   }
//   if (time.search("days") !== -1) {
//     return getDay();
//   }
//   return time;
// };
export const dateHandlerLatestMessage = (dateString) => {
    const date = new Date(dateString);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const today = new Date();
    const oneDayInMs = 24 * 60 * 60 * 1000;
    const diffDays = Math.floor((today - date) / oneDayInMs);

    let result;

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    const timeString = `${hours}:${minutesStr} ${ampm}`;

    if (date.toDateString() === today.toDateString()) {
        // If the date is today, return only the time
        result = timeString;
    } else if (diffDays < 7) {
        // If the date is within the last 7 days, return the day of the week and time
        const dayString = days[date.getDay()];
        result = `${dayString} ${timeString}`;
    } else {
        // Otherwise, return the date in DD/MM/YYYY format and time (UK format)
        const dayString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        result = `${dayString} ${timeString}`;
    }

    return result;
};

export const dateHandler = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
};

// export const dateHandler = (date) => {
//   let now = moment();
//   let momentDate = moment(date);
//   let time = momentDate.fromNow(true);
//   let dateByHourAndMin = momentDate.format("h:mm A"); // Change format to include AM/PM
  
//   const getDay = () => {
//     let days = time.split(" ")[0];
//     if (Number(days) < 8) {
//       return now.subtract(Number(days), "days").format("dddd");
//     } else {
//       return momentDate.format("DD/MM/YYYY");
//     }
//   };

//   if (time === "a few seconds") {
//     return "Now";
//   }
//   if (time.search("minute") !== -1) {
//     let mins = time.split(" ")[0];
//     if (mins === "a") {
//       return "1 min";
//     } else {
//       return `${mins} min`;
//     }
//   }
//   if (time.search("hour") !== -1) {
//     return dateByHourAndMin; // Return time with AM/PM format
//   }
//   if (time === "a day") {
//     return "Yesterday";
//   }
//   if (time.search("days") !== -1) {
//     return getDay();
//   }
//   return time;
// };

export const msgDateHandler = (date) => {
    const momentDate = moment(date);
    const now = moment();

    if (momentDate.isSame(now, 'day')) {
        return 'Today';
    } else if (momentDate.isSame(now.subtract(1, 'day'), 'day')) {
        return 'Yesterday';
    } else if (momentDate.isSame(now, 'week')) {
        return momentDate.format('dddd'); // Day of the week
    } else {
        return momentDate.format('DD/MM/YYYY'); // Older dates
    }
};