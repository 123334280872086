import React from 'react';
import {
  CameraIcon,
  ContactIcon,
  DocumentIcon,
  PollIcon,
  StickerIcon,
} from "../../svg";
import DocumentAttachment from "./DocumentAttachment";
import PhotoAttachment from "./PhotoAttachment";

const AttachmentMenu = () => {
	return (
		<>
			<div className="openEmojiAnimation">
				<ul>
			      {/*<li>
			        <button type="button" className="btn">
			          <PollIcon />
			        </button>
			      </li>*/}
			      {/*<li>
			        <button type="button" className="btn" style={{background: '#0EABF4'}}>
			          <ContactIcon />
			        </button>
			      </li>*/}
			      <DocumentAttachment />
			      {/*<li>
			        <button type="button" className="btn" style={{background: '#D3396D'}}>
			          <CameraIcon />
			        </button>
			      </li>
			      <li>
			        <button type="button" className="btn">
			          <StickerIcon />
			        </button>
			      </li>*/}
			      <PhotoAttachment />
			    </ul>
			</div>
		</>
	)
}

export default AttachmentMenu