import React,{useState,useEffect} from 'react';
import { ReturnIcon, ValidIcon } from "../svg";
import UnderlineInput from "./UnderlineInput";
import MultipleSelect from "./MultipleSelect";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import { createGroup, getConversations } from "../redux/features/chat/chatSlice";
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "../redux/features/auth/authSlice";

const CreateGroup = ({ setShowCreateGroup }) => {

	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const isLoggedIn = useSelector(selectIsLoggedIn);
	// useEffect(() => {
	//     dispatch(getLoginStatus());
	//     if (isLoggedIn && user === null) {
	//       dispatch(getUser());
	//     }

	// }, [dispatch, isLoggedIn, user]);
	const { isLoading } = useSelector((state) => state.chat);
	const [name, setName] = useState("");
	const [searchResults, setSearchResults] = useState([]);
  	const [selectedUsers, setSelectedUsers] = useState([]);

  	const handleSearchGroup = async (e) => {
	    if (e.target.value && e.key === "Enter") {
	      setSearchResults([]);
	      try {
	        const { data } = await axios.get(
	          `${process.env.REACT_APP_BACKEND_URL}/api/users?search=${e.target.value}`,
	          {
	            headers: {
	              Authorization: `Bearer ${user.token}`,
	            },
	          }
	        );
	        if (data.length > 0) {
	          let tempArray = [];
	          data.forEach((user) => {
	            let temp = {
	              value: user._id,
	              label: user.name,
	              picture: user.picture,
	            };
	            tempArray.push(temp);
	          });
	          setSearchResults(tempArray);
	        } else {
	          setSearchResults([]);
	        }
	      } catch (error) {
	        return toast.error("Failed to Load the Search Results");
	      }
	    } else {
	      setSearchResults([]);
	    }
	};

	const createGroupHandler = async () => {
	    if (isLoading !== true) {
	      let users = [];
	      selectedUsers.forEach((user) => {
	        users.push(user.value);
	      });
	      let values = {
	        name,
	        users,
	        // token,
	      };
	      // console.log(values)
	      // let groupChat = await dispatch(createGroup(values));
	      // await dispatch(getConversations(user.token));
	      // setShowCreateGroup(false);
	      try {
		      await dispatch(createGroup(values));
		      await dispatch(getConversations(user.token));
		      setShowCreateGroup(false);
		    } catch (error) {
		      console.error("Failed to create group and update conversations:", error);
		    }
	    }
	};

	const [windowHeight, setWindowHeight] = useState({ winHeight: window.innerHeight })

	const detectSize = () =>{
		setWindowHeight({
			winHeight: window.innerHeight 
		})
	}

	useEffect(() => {
	  window.addEventListener('resize', detectSize);
	  return () => {
	    window.removeEventListener('resize', detectSize)
	  }
	}, [windowHeight])

	return (
		<>
			<div className="createGroupSideBar" style={{height : windowHeight.winHeight - 50}}>
				<div className="createGroupHead">
					<button
			          className="btn"
			          onClick={() => setShowCreateGroup(false)}
			        >
			          <ReturnIcon />
			        </button>
			        <h5>Create Group</h5>
				</div>
		        <UnderlineInput name={name} setName={setName}/>
		        <MultipleSelect
		          selectedUsers={selectedUsers}
		          searchResults={searchResults}
		          setSelectedUsers={setSelectedUsers}
		          handleSearchGroup={handleSearchGroup}
		        />
		        <div className="createGroupBtn">
		          <button
		            className="btn"
		            onClick={() => createGroupHandler()}
		          >
		            {isLoading === true ? (
		              <ClipLoader color="#E9EDEF" size={25} />
		            ) : (
		              <ValidIcon />
		            )}
		        </button>
        		</div>
			</div>
		</>
	)
}

export default CreateGroup