import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import DashboardHeader from './DashboardHeader.js';
import DashboardSideBar from './DashboardSideBar.js';
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";


const AdminUsers = () => {

	useRedirectLoggedOutUser('/login')  

	return (
		<>
			<DashboardHeader />
			<div className="mainContainer">
				<DashboardSideBar/>
				<div className="dashboardContainer">
					
				</div>
			</div>		
		</>
	)
}

export default AdminUsers