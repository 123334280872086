import React from 'react';
import LoginForm from "../components/auth/LoginForm";

const Login = () => {
	
	return (
		<>
			<div className="">
				<LoginForm />
			</div>
		</>
	)
}

export default Login