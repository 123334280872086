import React,{useEffect,useState} from 'react';
import {
  CallIcon,
  DotsIcon,
  SearchLargeIcon,
  CloseIcon,
  VideoDialIcon,
} from "../../svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getConversationId,
  getConversationName,
  getConversationPicture,
  getConversationEmail,
} from "../../utils/chat";
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "../../redux/features/auth/authSlice";
import { FilterUser } from "../hiddenLinks";

const ChatHeader = ({chat,online,callUser,socket,videoCall,audioCall,isHidden,setIsHidden,searchQuery,setSearchQuery,handleGroupModalShow}) => {

	const dispatch = useDispatch();
	const { activeConversation } = useSelector((state) => state.chat);
  const { user } = useSelector((state) => state.auth);
  const [searchVisible, setSearchVisible] = useState(false);
  	// const {name,picture} = activeConversation;
  // useEffect(() => {
  //     dispatch(getUser());

  // }, [dispatch]);

  const backConversation = async () => {
	  setIsHidden(!isHidden);
	};

	const toggleSearch = () => {
      setSearchVisible(!searchVisible);
  };
  	
	return (
		<>
			<div className="chatHeaderSec">
				<div className="chatHeader">
				<div className="d-flex align-items-center">
					<button className="backConversation" onClick={() => backConversation()}><i className="fa fa-long-arrow-left"></i></button>
					<img src={activeConversation.isGroup ? activeConversation.picture : getConversationPicture(user, activeConversation.users)} alt='Picture'/>
					<div className="chatHeaderInfo">
						<div className="d-flex align-items-center">
							<h5>{activeConversation.isGroup ? activeConversation.name : getConversationName(user, activeConversation.users)}</h5>
							<small style={{marginLeft : '10px'}}>{online ? "(online)" : ""}</small>
						</div>
						<FilterUser>
						<small>
						{activeConversation.isGroup ? 
						<div className="d-flex">
						{activeConversation.users.length > 6 ? (
						    <>
						        {activeConversation.users.slice(0, 6).map((u, index) => (
						            <div className="grpUsersName" key={u._id}>
						                <span>{u._id === user?._id ? 'You' : u.name}{index < 1 ? ',' : ''}</span>
						            </div>
						        ))}
						        <span title={activeConversation.users.map(u => u._id === user._id ? 'You' : u.name).join(', ')}>...</span>
						    </>
						) : (
						    activeConversation.users.map((u, index) => (
						        <div className="grpUsersName" key={u._id}>
						            <span>{u._id === user?._id ? 'You' : u.name}{index < activeConversation.users.length - 1 ? ',' : ''}</span>
						        </div>
						    ))
						)}
	        	</div>
	        	 : getConversationEmail(user, activeConversation.users)}
	        	 </small>
						</FilterUser>
					</div>
				</div>
					<ul>
						{
							!activeConversation.isGroup && 
							(
								<FilterUser>
									{1 == 1 ? (
			            <li onClick={() => videoCall()}>
			              <button className="btn videoDialBtn">
			                <VideoDialIcon />
			              </button>
			            </li>
			          	) : null}
			          	{1 == 1 ? (
			            <li onClick={() => audioCall()}>
			              <button className="btn audioDialBtn">
			                <CallIcon />
			              </button>
			            </li>
			          	) : null}
			          </FilterUser>
							)
						}
						{
							activeConversation.isGroup && 
							(
								<FilterUser>
			            <li onClick={handleGroupModalShow}>
			              <button className="btn">
			                <i className="fa fa-eye"></i>
			              </button>
			            </li>
			          </FilterUser>
							)
						}
		        <li>
		        	<div className="chatSearch">
		            <button className="btn" onClick={toggleSearch}>
		              <SearchLargeIcon />
		            </button>
		            {searchVisible && (
		            	<>
                    <input
                        type="text"
                        placeholder="Search messages..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="" onClick={toggleSearch}>
                    	<CloseIcon />
                    </span>
                  </>
                )}
              </div>
		        </li>
		        {/*<li>
		            <button className="btn">
		              <DotsIcon />
		            </button>
		        </li>*/}
					</ul>
				</div>
			</div>
		</>
	)
}

export default ChatHeader