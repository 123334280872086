import React,{useState,useEffect,useRef} from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch, useSelector } from "react-redux";
import { register, RESET } from "../../redux/features/auth/authSlice";
import axios from "axios";
const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const cloud_secret = process.env.REACT_APP_UPLOAD_PRESET;

const initialState ={
	name : '',
	email : '',
	status : '',
	password : '',
}

const RegisterForm = () => {

	const [show, setShow] = useState(false);

	const showPassword = () => setShow(!show);

	const [formData, setFormData] = useState(initialState);
	const {name,email,status,password} = formData;
	const [picture, setPicture] = useState();
  	const [readablePicture, setReadablePicture] = useState("");
  	const inputRef = useRef();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { isLoading, isLoggedIn, isSuccess, message } = useSelector((state) => state.auth);
	
	const handleInputChange = (e) => {
		const {name, value} = e.target;
		setFormData({...formData, [name]: value});
	}

	const registerUser = async (e) => {
		e.preventDefault();

		if (!name || !email || !password || !picture){
			return toast.error('All fields are required')
		}

		// if (picture) {
		// 	//upload to cloudinary and then register user
	 //      await uploadImage().then(async (response) => {
	 //        const userData = {name, email, status, password, picture: response.secure_url};
	 //        let res = await dispatch(
	 //          register({ userData })
	 //        );
	 //        console.log(userData);
	 //        // if (res?.payload?.user) {
	 //        //   navigate("/");
	 //        // }
	 //      });
		// }

		// const userData = {name, email, status, password};
		
		// await dispatch(register(userData));

		// if (picture) {
	 //      //upload to cloudinary and then register user
	 //      await uploadImage().then(async (response) => {
	 //      	const userData = {name, email, status, password};
	 //        let res = await dispatch(
	 //          register({ userData, picture: response.secure_url })
	 //        );
	 //        // if (res?.payload?.user) {
	 //        //   navigate("/");
	 //        // }
	 //      });
	 //    } else {
	 //    	const userData = {name, email, status, password};
	 //      let res = await dispatch(register({ userData, picture: '' }));
	 //      // if (res?.payload?.user) {
	 //      //   navigate("/");
	 //      // }
	 //    }
	 // 	let imageURL;

	 // 	const image = new FormData();
	 //    image.append("upload_preset", cloud_secret);
	 //    image.append("file", picture);
	 //    const { response } = await fetch(
	 //      `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
	 //      { method: "post", body: image }
	 //    );
	 //    const imgData = await response.json();
  //       // console.log(imgData);
  //       imageURL = imgData.url.toString();

	 //    const userData = {name, email, status, password, picture: picture ? imageURL : picture};
		
		// await dispatch(register(userData));
		let imageURL;
		try{
			
			{

				const image = new FormData();
		        image.append("file", picture);
		        image.append("cloud_name", cloud_name);
		        image.append("upload_preset", cloud_secret);

		        // Save image to Cloudinary
		        const response = await fetch(
		          "https://api.cloudinary.com/v1_1/kbm/image/upload",
		          { method: "post", body: image }
		        );
		        const imgData = await response.json();
		        console.log(imgData);
		        imageURL = imgData.url.toString();

			}

			// Save profile to MongoDB
		    const userData = {
		        name: formData.name,
		        email: formData.email,
		        status: formData.status,
		        password: formData.password,
		        picture: picture ? imageURL : formData.picture,
		    };

			dispatch(register(userData));
		}catch(error){
			toast.error(error.message);
		}
	};

	useEffect(() =>{
		if (isSuccess && isLoggedIn) {
	        navigate("/login");
	    }
	    dispatch(RESET());
	},[isLoggedIn, isSuccess, dispatch, navigate])

	const hanldePicture = (e) => {
	    let pic = e.target.files[0];
	    if (
	      pic.type !== "image/jpeg" &&
	      pic.type !== "image/png" &&
	      pic.type !== "image/webp"
	    ) {
	      toast.error(`${pic.name} format is not supported.`);
	      return;
	    } else if (pic.size > 1024 * 1024 * 5) {
	      toast.error(`${pic.name} is too large, maximum 5mb allowed.`);
	      return;
	    } else {
	      // setError("");
	      setPicture(pic);
	      //reading the picture
	      const reader = new FileReader();
	      reader.readAsDataURL(pic);
	      reader.onload = (e) => {
	        setReadablePicture(e.target.result);
	      };
	    }
	};

	const handleChangePic = () => {
    	setPicture("");
    	setReadablePicture("");
    	inputRef.current.click();
  	};

 //  	const uploadImage = async () => {
	//     let imageData = new FormData();
	//     imageData.append("upload_preset", cloud_secret);
	//     imageData.append("file", picture);
	//     const { data } = await axios.post(
	//       `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
	//       imageData
	//     );
	//     return data;
	// };

	return (
		<>
			<div className="container">
			<div className="authForm d-flex flex-column align-items-center justify-content-center">
				<img src="../../fileImages/ACDAPlogoFinal.png" />
				<h3>KBM Chat Register</h3>
				<form onSubmit={registerUser} className="col-md-5 col-sm-6 col-12 mx-auto">
				  <div className="mb-3">
				    <input type="text" name="name" className="form-control" placeholder="Name" value={name} onChange={handleInputChange}/>
				  </div>
				  <div className="mb-3">
				    <input type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={handleInputChange}/>
				  </div>
				  <div className="mb-3">
				    <input type="text" name="status" className="form-control" placeholder="Status" value={status} onChange={handleInputChange}/>
				  </div>
				  <div className="mb-3 passwordField">
				    <input type={show? "text": "password"} name="password" className="form-control" placeholder="Password" value={password} onChange={handleInputChange}/>
				    <button type="button" onClick={showPassword}>{show ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}</button>
				  </div>
				  {/*<div className="profile_image_upload">
	               	<img src={imagePreview} alt="avatar"/>
	               	<input type="file" accept="image/*" name="image"/>
	              </div>*/}
	              <div className="profile_image_upload">
	              	{
	              		readablePicture ? 
	              		(
		              		<div className="d-flex flex-column align-items-baseline">
					          <img
					            src={readablePicture}
					            alt="picture"
					            className="w-20 h-20 object-cover rounded-full"
					          />
					          {/* change pic */}
					          <span
					            className="mt-2 w-20 py-1 dark:bg-dark_bg_3 rounded-md text-xs font-bold flex items-center justify-center cursor-pointer"
					            onClick={() => handleChangePic()}
					          >
					            Remove
					          </span>
					        </div>
				        )
				        :
				        (
				        	<span
					          className=""
					          onClick={() => inputRef.current.click()}
					        >
					          Upload picture
					        </span>
				        )
	              	}
	              	<input
				        type="file"
				        name="picture"
				        id="picture"
				        hidden
				        ref={inputRef}
				        accept="image/png,image/jpeg,image/webp"
				        onChange={hanldePicture}
				    />
	              </div>
				  <button type="submit" className="btn">
				  {isLoading === true ? (
	              	<PulseLoader color="#fff" size={10} />
	            	) : (
					  'Register'
					  )}
	              </button>
				</form>
			</div>
			</div>
		</>
	)
}

export default RegisterForm