import React from 'react';
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {
  logout,RESET,
} from "../../redux/features/auth/authSlice";

const DashboardHeader = () => {
	const { user } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logoutUser = async () => {
	    dispatch(RESET());
	    await dispatch(logout());
	    navigate("/login");
	    window.location.reload()
	};

	return (
		<>
			<div className="dashboardHeader">
				<div className="dashboardHeaderLogo">
					<Link to="/"><img src="../../fileImages/ACDAPlogoFinal.png" /></Link>
				</div>
				<ul>
					<li><Link to="/admin/dashboard"><span>Dashboard</span></Link></li>
					<li><Link to="/admin/feedback"><span>FeedBack</span></Link></li>
					<li>
					<div className="dashboardUserInfo">
				    {['bottom'].map((direction) => (
				      <Dropdown key={direction} drop={direction}>
				        <Dropdown.Toggle id={`dropdown-button-drop-${direction}`}>
				          <img src={user?.picture} alt={`${user?.name}'s profile`} /> {user?.name}
				        </Dropdown.Toggle>

				        <Dropdown.Menu>
				          <Dropdown.Item onClick={logoutUser}>Logout</Dropdown.Item>
				        </Dropdown.Menu>
				      </Dropdown>
				    ))}
				  </div>
				  </li>
				</ul>
			</div>	
		</>
	)
}

export default DashboardHeader