import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const ChatLoading = () => {
	return (
		<>
			<div className="mb-2 mt-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
			<div className="mb-2">
				<Skeleton variant="rectangular" height={20} />
			</div>
		</>
	)
}

export default ChatLoading