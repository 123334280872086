import React,{useRef} from 'react';
import { CloseIcon } from "../../../svg";
import { useDispatch } from "react-redux";
import { addFiles } from "../../../redux/features/chat/chatSlice";
import { getFileType } from "../../../utils/file";

const AddFile = () => {

	const dispatch = useDispatch();
  	const inputRef = useRef(null);

  	// const filestHandler = (e) => {
	//     let files = Array.from(e.target.files);
	//     files.forEach((file) => {
	//       if (
	//         file.type !== "application/pdf" &&
	//         file.type !== "text/plain" &&
	//         file.type !== "application/msword" &&
	//         file.type !==
	//           "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
	//         file.type !== "application/vnd.ms-powerpoint" &&
	//         file.type !==
	//           "application/vnd.openxmlformats-officedocument.presentationml.presentation" &&
	//         file.type !== "application/vnd.ms-excel" &&
	//         file.type !==
	//           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
	//         file.type !== "application/vnd.rar" &&
	//         file.type !== "application/zip" &&
	//         file.type !== "audio/mpeg" &&
	//         file.type !== "audio/wav" &&
	//         file.type !== "image/png" &&
	//         file.type !== "image/jpeg" &&
	//         file.type !== "image/gif" &&
	//         file.type !== "image/webp" &&
	//         file.type !== "video/mp4" &&
	//         file.type !== "video/mpeg" &&
	//         file.type !== "application/x-troff-msvideo" &&
	//         file.type !== "video/avi" &&
	//         file.type !== "video/msvideo" &&
	//         file.type !== "video/x-msvideo" &&
	//         file.type !== "image/webm" &&
	//         file.type !== "image/webp"
	//       ) {
	//         files = files.filter((item) => item.name !== file.name);
	//         return;
	//       } else if (file.size > 1024 * 1024 * 10) {
	//         files = files.filter((item) => item.name !== file.name);
	//         return;
	//       } else {
	//         const reader = new FileReader();
	//         reader.readAsDataURL(file);
	//         reader.onload = (e) => {
	//           dispatch(
	//             addFiles({
	//               file: file,
	//               fileData:
	//                 getFileType(file.type) === "IMAGE" || "VIDEO" ? e.target.result : "",
	//               type: getFileType(file.type),
	//             })
	//           );
	//         };
	//       }
	//     });
	// };

	const filestHandler = (e) => {
    let files = Array.from(e.target.files);
    files.forEach((file) => {
        if (file.size > 1024 * 1024 * 10) {
            files = files.filter((item) => item.name !== file.name);
            return;
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                dispatch(
                    addFiles({
                        file: file,
                        fileData: getFileType(file.type) === "IMAGE" || "VIDEO" ? e.target.result : "",
                        type: getFileType(file.type),
                    })
                );
            };
        }
    });
}; 

	return (
		<>
			<div className="addFileSec" onClick={() => inputRef.current.click()}>
				<span className="addFileBtn">
					<CloseIcon />
				</span>
			</div>	
			<input
		        type="file"
		        hidden
		        multiple
		        ref={inputRef}
		        accept="*/*"
		        // accept="application/*,text/plain,image/png,image/jpeg,image/gif,image/webp,video/mp4,video/mpeg,video/avi,"
		        onChange={filestHandler}
		    />
		</>
	)
}

export default AddFile