import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import AddFile from "./AddFile";
import { toast } from "react-toastify";
import { CloseIcon, SendIcon } from "../../../svg";
// import { uploadFiles } from "../../../utils/upload";
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "../../../redux/features/auth/authSlice";
import {
  removeFileFromFiles,
  sendMessage,
} from "../../../redux/features/chat/chatSlice";
import SocketContext from "../../../context/SocketContext";
import ClipLoader from "react-spinners/ClipLoader";
import VideoThumbnail from "react-video-thumbnail";
import AWS from "aws-sdk";

const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const cloud_secret = process.env.REACT_APP_UPLOAD_PRESET;

const bucketAccess = process.env.REACT_APP_ACCESS_KEY;
const bucketSecret = process.env.REACT_APP_SECRET_KEY;
const bucketName = process.env.REACT_APP_BUCKET_NAME;
const bucketRegion = process.env.REACT_APP_REGION;

// const bucketAccess = 'AKIAZXZAVTTZYTF6LSXA';
// const bucketSecret = '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';
// const bucketName = 'crmone';
// const bucketRegion = 'us-east-2';

const HandleAndSend = ({ activeIndex, setActiveIndex, message, socket }) => {

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const { files, activeConversation } = useSelector((state) => state.chat);
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const user = useSelector(selectUser);
	// useEffect(() => {
	//     dispatch(getLoginStatus());
	//     if (isLoggedIn && user === null) {
	//       dispatch(getUser());
	//     }

	// }, [dispatch, isLoggedIn, user]);

	// console.log(cloud_name);
	// console.log(bucketAccess);

	// const sendMessageHandler = async (e) =>{
	// 	e.preventDefault();
	// 	setLoading(true);
	// 	const uploaded_files = await uploadFiles(files);
	// 	console.log(uploaded_files)
		
	// 		// const values = {
	//   //     // token,
	//   //     message,
	//   //     chat_id: activeConversation._id,
	//   //     files: uploaded_files?.length > 0 ? uploaded_files : [],
	//   //   };
	// 		// console.log(values);
	//   //   let newMsg = await dispatch(sendMessage(values));
	//   //   socket.emit("send message", newMsg.payload);
	//   //   setLoading(false);
	// }

// 	const s3 = new AWS.S3({
//   accessKeyId: bucketAccess,
//   secretAccessKey: bucketSecret,
//   region: bucketRegion, 
// });

// 	const sendMessageHandler = async (e) => {
//   e.preventDefault();
//   setLoading(true);
//   let fileUrl = [];

//   try {
//     for (const f of files) {
//       const { file, type } = f;

//       // let key = `${Date.now()}_/demo.png`;

//       // if (activeConversation && activeConversation._id) {
//       //     key = `${activeConversation._id}_${key}`;
//       // }

//       // Prepare parameters for S3 upload
//       // const params = {
//       //   Bucket: bucketName,
//       //   Key: key, // Unique key for the file
//       //   Body: file,
//       //   ContentType: type,
//       // };

//       // Upload file to S3
//       // const uploadResult = await s3.upload(params).promise();
//       // console.log(uploadResult);

//       fileUrl.push({
//         file: file,
//         type: type,
//       });
//     }

//     const values = {
//       message,
//       chat_id: activeConversation._id,
//       files: fileUrl.length > 0 ? fileUrl : [],
//     };

//     console.log(values);

//     let newMsg = await dispatch(sendMessage(values));
//     socket.emit("send message", newMsg.payload);
//     setLoading(false);
//   } catch (error) {
//     toast.error(error.message);
//   }
// };

	// const sendMessageHandler = async (e) =>{
	// 	e.preventDefault();
	// 	setLoading(true);
	// 	let fileUrl = [];
	// 	try{
	// 		{
	// 			const formData = new FormData();
	// 			formData.append("upload_preset", cloud_secret);
	// 			for (const f of files) {
	// 		    const { file, type } = f;
	// 		    formData.append("file", file);
	// 		    // let res = await uploadToCloudinary(formData, type);
	// 		    const res = await fetch(
	// 	          "https://api.cloudinary.com/v1_1/kbm/raw/upload",
	// 	          { method: "post", body: formData }
	// 	        );
	// 		    const imgData = await res.json();
	// 		    fileUrl.push({
	// 		      file: imgData,
	// 		      type: type,
	// 		    });
	// 		    console.log(imgData)
	// 		    // fileUrl = imgData.url.toString();
	// 		  }
	// 		}
	// 		{
	// 			const values = {
	// 	      // token,
	// 	      message,
	// 	      chat_id: activeConversation._id,
	// 	      files: fileUrl.length > 0 ? fileUrl : [],
	// 	    };
	// 			console.log(values);
	// 	    let newMsg = await dispatch(sendMessage(values));
	// 	    socket.emit("send message", newMsg.payload);
	// 	    setLoading(false);
	// 		}
	// 	}catch(error){
	// 		toast.error(error.message);
	// 	}
	// }

	AWS.config.update({
  accessKeyId: bucketAccess,
  secretAccessKey: bucketSecret,
  region: bucketRegion // e.g., 'us-east-1'
});

const s3 = new AWS.S3();

const sendMessageHandler = async (e) => {
  e.preventDefault();
  setLoading(true);
  let fileUrl = [];
  try {
    for (const f of files) {
      const { file, type } = f;
      
      // Configure parameters for uploading to S3
      const params = {
        Bucket: bucketName,
        Key: `chat/${file.name}`, // Set the key/name under which the file will be stored in S3
        Body: file,
        ContentType: type // Set the content type of the file
      };

      // Upload file to S3
      const uploadPromise = s3.upload(params).promise();
      const data = await uploadPromise;

      // Construct the URL of the uploaded file
      // const fileUrl = data.Location;

      fileUrl.push({
        file: data.Location,
        type: type,
        name: file.name,
        size: file.size,
      });
    }

    const values = {
      message,
      chat_id: activeConversation._id,
      files: fileUrl.length > 0 ? fileUrl : [],
    };
    // console.log(values);
    let newMsg = await dispatch(sendMessage(values));
    socket.emit("send message", newMsg.payload);
    setLoading(false);
  } catch (error) {
    toast.error(error.message);
  }
}

	const handleRemoveFile = (index) => {
    dispatch(removeFileFromFiles(index));
  };

	return (
		<>
			<div className="fileHandleAndSend">
				{
					files.map((file,i)=>(
						<div key={i} className={`filePreviewBottomFile`} onClick={() => setActiveIndex(i)}>
							{
								file.type === "IMAGE" ?
								(<img className={`${activeIndex === i ? "online" : ""}`} src={file.fileData} /> ):
								file.type === "VIDEO" ?
								(<VideoThumbnail videoUrl={file.fileData} /> ):
								<div className="fileSmallIcon">
									<img className={`${activeIndex === i ? "online" : ""}`} src={`../../../fileImages/${file.type}.png`} />
								</div>
							}
							<div
	              className="removeFileIcon"
	              onClick={() => handleRemoveFile(i)}
	            >
	              <CloseIcon />
	            </div>
						</div>
					))
				}
				<AddFile setActiveIndex={setActiveIndex} />
				<div className="sendFileIcon" onClick={(e) => sendMessageHandler(e)}>
					{loading ? (
	          <ClipLoader color="#E9EDEF" size={25} />
	        ) : (
	          <SendIcon />
	        )}
				</div>
			</div>
		</>
	)
}

const HandleAndSendWithContext = (props) => (
  <SocketContext.Consumer>
    {(socket) => <HandleAndSend {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default HandleAndSendWithContext;