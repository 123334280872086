import React from 'react'

const FilesPreviewInput = ({message, setMessage}) => {
	return (
		<>
			<div className="filesPreviewInput">
				<input
			        type="text"
			        placeholder="Type a message"
			        value={message}
			        onChange={(e) => setMessage(e.target.value)}
			        className="form-control"
			    />
			</div>	
		</>
	)
}

export default FilesPreviewInput