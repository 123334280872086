import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {toast} from 'react-toastify';
import authService from './authService';

const initialState = {
  isLoggedIn: false,
	user: null,
	users: [],
	shiftBookings: [],
	isError: false,
	isSuccess: false,
	isLoading: false,
	message: "",
};


export const register = createAsyncThunk (
	"auth/register",
	async (userData, thunkAPI) => {
		try{
			return await authService.register(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// login user
export const login = createAsyncThunk (
	"auth/login",
	async (userData, thunkAPI) => {
		try{
			return await authService.login(userData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// logout user
export const logout = createAsyncThunk (
	"auth/logout",
	async (_, thunkAPI) => {
		try{
			return await authService.logout();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get login status
export const getLoginStatus = createAsyncThunk (
	"auth/getLoginStatus",
	async (_, thunkAPI) => {
		try{
			return await authService.getLoginStatus();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get user
export const getUser = createAsyncThunk (
	"auth/getUser",
	async (_, thunkAPI) => {
		try{
			return await authService.getUser();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//getByDefaultUsers
export const getByDefaultUsers = createAsyncThunk (
	"auth/getByDefaultUsers",
	async (_, thunkAPI) => {
		try{
			return await authService.getByDefaultUsers();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//getCalendarData
export const getCalendarData = createAsyncThunk (
	"auth/getCalendarData",
	async (_, thunkAPI) => {
		try{
			return await authService.getCalendarData();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// export const getTrainers = createAsyncThunk (
// 	"auth/getTrainers",
// 	async (_, thunkAPI) => {
// 		try{
// 			return await authService.getTrainers();
// 		}catch(error){
// 			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
// 			return thunkAPI.rejectWithValue(message);
// 		}
// 	}
// )
export const createComplaint = createAsyncThunk (
	"auth/createComplaint",
	async (_, thunkAPI) => {
		try{
			return await authService.createComplaint();
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

export const authSlice = createSlice({
	name: "auth",
  	initialState,
  	reducers: {
  		RESET: (state) => {
	      state.isError = false;
				state.isSuccess = false;
				state.isLoading = false;
				state.message = '';
  		},
  	},
  	extraReducers(builder){
  		builder
			.addCase(register.pending, (state, action) => {
	        state.isLoading = true;
	      })
	      .addCase(register.fulfilled, (state, action) => {
	        state.isLoading = false;
					state.isSuccess = true;
					state.isLoggedIn = true;
	        state.user = action.payload.user;
	        toast.success("Registration Successful");
	      })
	      .addCase(register.rejected, (state, action) => {
	        state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					state.user = null;
					toast.error(action.payload);
	      })

		      //login user
				.addCase(login.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(login.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.isLoggedIn = true;
					state.user = action.payload;
					toast.success("Login Successful");
				})
				.addCase(login.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					state.user = null;
					toast.error(action.payload);
					// if (action.payload.includes("New browser")) {
			  //         state.twoFactor = true;
			  //       }
				})

					//get login status
				.addCase(getLoginStatus.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(getLoginStatus.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.isLoggedIn = action.payload;
				})
				.addCase(getLoginStatus.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
				})

				//get all users active
				.addCase(getByDefaultUsers.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(getByDefaultUsers.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.users = action.payload;
				})
				.addCase(getByDefaultUsers.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					toast.error(action.payload);
				})

			//get all users active
			// .addCase(getTrainers.pending, (state)=>{
			// 	state.isLoading = true;
			// })
			// .addCase(getTrainers.fulfilled, (state, action)=>{
			// 	state.isLoading = false;
			// 	state.isSuccess = true;
			// 	state.users = action.payload;
			// })
			// .addCase(getTrainers.rejected, (state, action)=>{
			// 	state.isLoading = false;
			// 	state.isError = true;
			// 	state.message = action.payload;
			// 	toast.error(action.payload);
			// })


			//create complaint
			.addCase(createComplaint.pending, (state)=>{
				state.isLoading = true;
			})
			.addCase(createComplaint.fulfilled, (state, action)=>{
				state.isLoading = false;
				state.isSuccess = true;
				state.users = action.payload;
			})
			.addCase(createComplaint.rejected, (state, action)=>{
				state.isLoading = false;
				state.isError = true;
				state.message = action.payload;
				toast.error(action.payload);
			})

				// get user
				.addCase(getUser.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(getUser.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.isLoggedIn = true;
					state.user = action.payload;
				})
				.addCase(getUser.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					toast.error(action.payload);
				})

					//logout user
				.addCase(logout.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(logout.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.isLoggedIn = false;
					state.user = null;
					toast.success(action.payload);
				})
				.addCase(logout.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					toast.error(action.payload);
				})

				//getCalendarData
				.addCase(getCalendarData.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(getCalendarData.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.shiftBookings = action.payload;
				})
				.addCase(getCalendarData.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					toast.error(action.payload);
				})
  	}
});

export const { RESET } = authSlice.actions;
export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;