import React,{useState,useEffect,useLayoutEffect} from 'react';
import { Routes, Route } from "react-router-dom";
import { io } from "socket.io-client";
import { useDispatch,useSelector } from "react-redux";
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import Blank from './pages/Blank.js';
import Home from './pages/Home.js';
import Room from './pages/Room.js';
import Dashboard from './pages/admin/Dashboard.js';
import AdminUsers from './pages/admin/AdminUsers.js';
import Feedback from './pages/admin/Feedback.js';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "./redux/features/auth/authSlice";
import SocketContext from "./context/SocketContext";

axios.defaults.withCredentials = true;
const socket = io.connect(process.env.REACT_APP_BACKEND_URL);

function App() {

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
 // const user  = useSelector((state) => state.auth);
  const user = useSelector(selectUser);
  // console.log(user);
  useEffect(() => {
    // Dispatch only once on mount
    dispatch(getLoginStatus());
  }, [dispatch]);

  useEffect(() => {
    // Fetch user data only if logged in and user data is not already fetched
    if (isLoggedIn && user === null) {
      dispatch(getUser());
    }
  }, [dispatch, isLoggedIn, user]);


  return (
    <>
    <ToastContainer />
    <SocketContext.Provider value={socket}>
      <Routes>
          {/*<Route path="/blank" element={
              <Blank/>
          }/>
          <Route path="/:id" element={
              <Login/>
          }/>*/}
          <Route path="/login" element={
              <Login/>
          }/>
          <Route path="/register" element={
              <Register/>
          }/>
          <Route exact path="/" element={
              <Home socket={socket}/>
          }/>
          <Route path="/room/:roomId" element={
            <Room/>
          }/>
          <Route path="/admin/dashboard" element={
            <Dashboard/>
          }/>
          <Route path="/admin/users" element={
            <AdminUsers/>
          }/>
          <Route path="/admin/feedback" element={
            <Feedback/>
          }/>
      </Routes>
    </SocketContext.Provider>
    </>
  );
}

export default App;