import React,{useState,useEffect} from 'react';
import SidebarHeader from "./SidebarHeader";
import Notifications from "./Notifications";
import Search from "./Search";
import { useDispatch,useSelector } from "react-redux";
import Conversations from "./Conversations";
import SearchResults from "./SearchResults";
import {
  getConversations,
  updateMessagesAndConversations,
} from "../redux/features/chat/chatSlice";
import {
  getByDefaultUsers,
  getTrainers,
} from "../redux/features/auth/authSlice";
import { FilterUser } from "./hiddenLinks";
import axios from "axios";
import Contact from "./Contact";
import ChatLoading from "./ChatLoading";

const Sidebar = ({onlineUsers,typing,isHidden,setIsHidden,unreadCount,setUnreadCount,defaultUsers}) => {

	const dispatch = useDispatch();
	const [searchResults, setSearchResults] = useState([]);
	const { user, users, loading, shiftBookings } = useSelector((state) => state.auth);
	// const [defaultUsers, setDefaultUsers] = useState([]);
	const [isDisabled, setIsDisabled] = useState(false);
	// const [loading, setLoading] = useState(true);
	// console.log(user?.role);
	
	// useEffect(() => {
  //   // if (user === null) {
  //   //   dispatch(getConversations(user));
  //   // }
	// 	if (user?.email === "online.training1@kbmgroup.co.uk") {
  //     dispatch(getByDefaultUsers());
  //   }
  // }, [dispatch]);

  useEffect(() => {    
  if (user?.roles?.includes('student')) {
    dispatch(getByDefaultUsers());
  }
}, [user, dispatch]);

  // useEffect(() => {
  //   if (!user || !users || users.length === 0 || !shiftBookings || shiftBookings.length === 0) {
  //     return;
  //   }
  //   // const assignRandomNames = (users) => {
  //   //   const names = ["Barking", "Park Royal", "Training 3"];
  //   //   return users.map((user, index) => ({
  //   //     ...user,
  //   //     name: names[index % names.length]
  //   //   }));
  //   // };

  //   // const usersWithRandomNames = assignRandomNames(users);
  //   setDefaultUsers(users);
  //       console.log(shiftBookings);
  //   const shouldShowDefaultUser = (userId) => {
  //     const currentDate = new Date();
  //     const today = currentDate.toISOString().split('T')[0];

  //     const sortedBookings = [...shiftBookings].sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));

  //     const filteredBookings = sortedBookings.filter(bookingData => {
  //           const bookingDate = new Date(bookingData.date).toISOString().split('T')[0];
  //           console.log(bookingDate === today);
  //           return bookingDate === today;
  //         });console.log(filteredBookings)

  //     for (const bookingData of filteredBookings) {
  //       const isUserInBooking = bookingData.bookings.some(b => b.user === userId && b.status === 'Active' && (b.deleted_at === undefined || b.deleted_at === null));

  //       if (isUserInBooking) {
  //         const targetDate = new Date(bookingData.date);
  //         const location = bookingData.location;

  //         if (!targetDate || !location) {
  //           continue;
  //         }

  //         switch (location) {
  //           case 'Online Group 1 - KBM_training1':
  //             if (currentDate) {
  //               // setIsEnabled(true);
  //               setDefaultUsers(users.filter(user => user.name === 'Online Training 1'));
  //               return true;
  //             }
  //             break;
  //           case 'Online Group 2 - KBM_training2':
  //             if (currentDate) {
  //               // setIsEnabled(true);
  //               setDefaultUsers(users.filter(user => user.name === 'Online Training 2'));
  //               return true;
  //             }
  //             break;
  //           case 'Park Royal':
  //             if (currentDate) {
  //               // setIsEnabled(true);
  //               // setDefaultUsers(users.filter(user => user.name === 'Online Training 1'));
                
  //               setIsDisabled(true);
  //               setDefaultUsers(users);

  //               const findUser = users.find(user => user.name === 'Online Training 1');
  //               if (findUser) {
  //     						setIsDisabled(false);
  //               }

  //               return true;
  //             }
  //             break;
  //           case 'Barking':
  //             if (currentDate) {
  //               // setIsEnabled(true);
  //               setDefaultUsers(users.filter(user => user.name === 'Barking'));
  //               return true;
  //             }
  //             break;
  //           case 'Birmingham':
  //             if (currentDate) {
  //               // setIsEnabled(true);
  //               setDefaultUsers(users.filter(user => user.name === 'Birmingham'));
  //               return true;
  //             }
  //             break;
  //           case 'Croydon':
  //             if (currentDate) {
  //               // setIsEnabled(true);
  //               setDefaultUsers(users.filter(user => user.name === 'Croydon'));
  //               return true;
  //             }
  //             break;
  //           default:
  //             // setIsEnabled(false);
  //             setDefaultUsers(users);
  //         }
  //         // if (location === 'Park Royal' && currentDate) {
  //         //   setIsEnabled(true);
  //         //   setDefaultUsers(usersWithRandomNames.filter(user => user.name === 'Barking'));
  //         //   return true;
  //         // }
  //       }
  //     }

  //     // setIsEnabled(false);
  //     return false;
  //   };

  //   if (user) {
  //     shouldShowDefaultUser(user._id);
  //   }

  // }, [user, users, shiftBookings]);

  if (loading) {
        return <ChatLoading />;
    }

    // console.log(defaultUsers)

	return (
		<>

		<div className={`sideBarMain ${isHidden ? 'customNone' : 'customBlock'}`}>
			<SidebarHeader />
			<Notifications />
		<div>
    </div>
				<FilterUser>
					<Search searchLength={searchResults.length} setSearchResults={setSearchResults} />
				</FilterUser>
				{searchResults.length > 0 ? (
			        <>
			          {/*Search results*/}
			          <SearchResults searchResults={searchResults} setSearchResults={setSearchResults}/>
			        </>
			      ) : (
			        <>

			        {user?.roles?.some(role => role === 'student') && (
			        	<div className="defaultResults">
									<ul>
					          {defaultUsers && defaultUsers.map((user) => (
			                <Contact
			                  contact={user}
			                  key={user._id}
			                  setSearchResults={setSearchResults}
			                  isDisabled={isDisabled}
			                />
			              ))}
			              {(!defaultUsers) && (
			                <li>No User</li>
			              )}
					        </ul>
								</div>
  						)}
			          {/*Conversations*/}
			          <Conversations onlineUsers={onlineUsers} typing={typing} isHidden={isHidden} setIsHidden={setIsHidden} unreadCount={unreadCount} setUnreadCount={setUnreadCount} defaultUsers={defaultUsers}/>
			        </>
			    )}
			</div>
			
		</>
	)
}

export default Sidebar