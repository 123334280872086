import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {toast} from 'react-toastify';
import chatService from './chatService';
import axios from "axios";

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  conversation: null,
  conversations: [],
  activeConversation: {},
  message: null,
  messages: [],
  notifications: [],
  files: [],
};

export const getConversations = createAsyncThunk (
	"chat/getConversations",
	async (token, thunkAPI) => {
		try{
			return await chatService.getConversations(token);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

export const getConversationById = createAsyncThunk (
	"chat/getConversationById",
	async (id, thunkAPI) => {
		try{
			return await chatService.getConversationById(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

export const open_contact = createAsyncThunk (
	"chat/open_contact",
	async (values, thunkAPI) => {
		const { token, receiver_id } = values;
		try{
			return await chatService.open_contact(values);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

export const open_create_conversation = createAsyncThunk (
	"chat/open_create_conversation",
	async (values, thunkAPI) => {
		const { token, receiver_id, isGroup } = values;
		try{
			return await chatService.open_create_conversation(values);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

export const getConversationMessages = createAsyncThunk (
	"chat/getConversationMessages",
	async ({chat_id, token}, thunkAPI) => {
		try{
			return await chatService.getConversationMessages({chat_id, token});
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

export const sendMessage = createAsyncThunk (
	"chat/sendMessage",
	async (values, thunkAPI) => {
		try{
			return await chatService.sendMessage(values);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

export const createGroup = createAsyncThunk (
	"chat/createGroup",
	async (values, thunkAPI) => {
		const { token, name, users } = values;
		try{
			return await chatService.createGroup(values);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// get message by id
export const getMessageById = createAsyncThunk (
	"chat/getMessageById",
	async (id, thunkAPI) => {
		try{
			return await chatService.getMessageById(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

// update message
export const updateMessage = createAsyncThunk (
	"chat/updateMessage",
	async ({id, messageData}, thunkAPI) => {
		try{
			return await chatService.updateMessage(id, messageData);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//delete message
export const deleteMessage = createAsyncThunk (
	"chat/deleteMessage",
	async (id, thunkAPI) => {
		try{
			return await chatService.deleteMessage(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

export const deleteFile = createAsyncThunk(
    'chat/deleteFile',
    async ({ messageId, fileUrl }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/message/${messageId}/files`, {
                data: { fileUrl }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

//delete conversation
export const deleteConversation = createAsyncThunk (
	"chat/deleteConversation",
	async (id, thunkAPI) => {
		try{
			return await chatService.deleteConversation(id);
		}catch(error){
			const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
			return thunkAPI.rejectWithValue(message);
		}
	}
)

//rename group
export const renameGroup = createAsyncThunk(
  'chat/renameGroup',
  async ({ conversationId, groupChatName }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/api/conversation/rename/${conversationId}`,
      { groupChatName },
      config
    );

    return { conversationId, groupChatName };
  }
);

//add user to group
export const addToGroup = createAsyncThunk(
  'chat/addToGroup',
  async ({ conversationId, userId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/conversation/groupadd/${conversationId}`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Adjust this to use your token logic
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const leftGroup = createAsyncThunk(
  'chat/leftGroup',
  async ({ conversationId, userId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/conversation/leftgroup/${conversationId}`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeUserFromGroup = createAsyncThunk(
  'chat/removeUserFromGroup',
  async ({ conversationId, userId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/conversation/groupremoveuser/${conversationId}`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const chatSlice = createSlice({
	name: "chat",
  	initialState,
  	reducers:{
	  		setActiveConversation: (state, action) => {
	      state.activeConversation = action.payload;
	    },
	    	updateMessagesAndConversations: (state, action) => {
	    		let chat = state.activeConversation;
		      if (chat._id === action.payload.conversation?._id) {
		        state.messages = [...state.messages, action.payload];
		      }

		      //update conversations
			    let conversation = {
		        ...action.payload.conversation,
		        latestMessage: action.payload,
		      };
		      let newChat = [...state.conversations].filter(
		        (c) => c._id !== conversation._id
		      );
			      newChat.unshift(conversation);
			      state.conversations = newChat;
	    },
		    addFiles: (state, action) => {
	      state.files = [...state.files, action.payload];
	    },
	    	clearFiles: (state, action) => {
	      state.files = [];
	    },
	    	removeFileFromFiles: (state, action) => {
	      let index = action.payload;
	      let files = [...state.files];
	      let fileToRemove = [files[index]];
	      state.files = files.filter((file) => !fileToRemove.includes(file));
	    },
  	},
  	extraReducers(builder){
  		builder
  		.addCase(renameGroup.fulfilled, (state, action) => {
	      const { conversationId, groupChatName } = action.payload;
	      if (state.activeConversation?._id === conversationId) {
	        state.activeConversation.name = groupChatName;
	      }
	      const conversation = state.conversations.find(conv => conv._id === conversationId);
	      if (conversation) {
	        conversation.name = groupChatName;
	      }
	    })
	    
	    .addCase(addToGroup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addToGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        const { conversationId, userId } = action.meta.arg;
        const conversation = state.conversations.find(c => c._id === conversationId);
        if (conversation) {
          conversation.users.push(userId);
          if (state.activeConversation && state.activeConversation._id === conversationId) {
            state.activeConversation.users.push(userId);
          }
        }
      })
      .addCase(addToGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle removeFromGroup action
      .addCase(removeUserFromGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeUserFromGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        const { conversationId, userId } = action.meta.arg;
        const conversation = state.conversations.find(c => c._id === conversationId);
        if (conversation) {
          conversation.users = conversation.users.filter(u => u._id !== userId);
          if (state.activeConversation && state.activeConversation._id === conversationId) {
            state.activeConversation.users = state.activeConversation.users.filter(u => u._id !== userId);
          }
        }
      })
      .addCase(removeUserFromGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(leftGroup.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(leftGroup.fulfilled, (state, action) => {
        state.isLoading = false;
        const { conversationId, userId } = action.meta.arg;
        state.conversations = state.conversations.filter(c => c._id !== conversationId);
        if (state.activeConversation && state.activeConversation._id === conversationId) {
          state.activeConversation = null;
        }
      })
      .addCase(leftGroup.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

	  		.addCase(getConversations.pending, (state, action) => {
		        state.isLoading = true;
		    })
		    .addCase(getConversations.fulfilled, (state, action) => {
		        state.isLoading = false;
						state.isSuccess = true;
						state.isError = false;
		        state.conversations = action.payload;
		    })
		    .addCase(getConversations.rejected, (state, action) => {
		        state.isLoading = false;
		        state.isSuccess = false;
						state.isError = true;
		        toast.error(action.payload);
		    })
		    .addCase(getConversationById.pending, (state, action) => {
		        state.isLoading = true;
		    })
		    .addCase(getConversationById.fulfilled, (state, action) => {
		        state.isLoading = false;
						state.isSuccess = true;
						state.isError = false;
		        state.conversation = action.payload;
		    })
		    .addCase(getConversationById.rejected, (state, action) => {
		        state.isLoading = false;
		        state.isSuccess = false;
						state.isError = true;
		        toast.error(action.payload);
		    })
		    .addCase(open_contact.pending, (state, action) => {
		        state.isLoading = true;
		    })
		    .addCase(open_contact.fulfilled, (state, action) => {
		        state.isLoading = false;
						state.isSuccess = true;
						state.isError = false;
		        state.activeConversation = action.payload;
		        state.files = [];
		    })
		    .addCase(open_contact.rejected, (state, action) => {
		        state.isLoading = false;
		        state.isSuccess = false;
						state.isError = true;
		        toast.error(action.payload);
		    })
		    .addCase(open_create_conversation.pending, (state, action) => {
		        state.isLoading = true;
		    })
		    .addCase(open_create_conversation.fulfilled, (state, action) => {
		        state.isLoading = false;
						state.isSuccess = true;
						state.isError = false;
		        state.activeConversation = action.payload;
		        state.files = [];
		    })
		    .addCase(open_create_conversation.rejected, (state, action) => {
		        state.isLoading = false;
		        state.isSuccess = false;
						state.isError = true;
		        toast.error(action.payload);
		    })
		    .addCase(createGroup.pending, (state, action) => {
		        state.isLoading = true;
		    })
		    .addCase(createGroup.fulfilled, (state, action) => {
		        state.isLoading = false;
						state.isSuccess = true;
						state.isError = false;
		        state.conversations.unshift(action.payload);
		        state.files = [];
		    })
		    .addCase(createGroup.rejected, (state, action) => {
		        state.isLoading = false;
		        state.isSuccess = false;
						state.isError = true;
		        toast.error(action.payload);
		    })
		    .addCase(getConversationMessages.pending, (state, action) => {
		        state.isLoading = true;
		    })
		    .addCase(getConversationMessages.fulfilled, (state, action) => {
		        state.isLoading = false;
						state.isSuccess = true;
						state.isError = false;
		        state.messages = action.payload;
		    })
		    .addCase(getConversationMessages.rejected, (state, action) => {
		        state.isLoading = false;
		        state.isSuccess = false;
						state.isError = true;
		        toast.error(action.payload);
		    })
		    .addCase(sendMessage.pending, (state, action) => {
		        state.isLoading = true;
		    })
		    .addCase(sendMessage.fulfilled, (state, action) => {
		        state.isLoading = false;
						state.isSuccess = true;
						state.isError = false;
		        state.messages = [...state.messages, action.payload];
		        let conversation = {
		          ...action.payload.conversation,
		          latestMessage: action.payload,
		        };
		        let newChat = [...state.conversations].filter(
		          (c) => c._id !== conversation._id
		        );
		        newChat.unshift(conversation);
        		state.conversations = newChat;
        		state.files = [];
		    })
		    .addCase(sendMessage.rejected, (state, action) => {
		        state.isLoading = false;
		        state.isSuccess = false;
						state.isError = true;
		        toast.error(action.payload);
		    })

		    //get message by id
				.addCase(getMessageById.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(getMessageById.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.isError = false;
					state.message = action.payload;
				})
				.addCase(getMessageById.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					toast.error(action.payload);
				})

				//update message
				.addCase(updateMessage.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(updateMessage.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.message = action.payload;
					toast.success("Message is Updated");
				})
				.addCase(updateMessage.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					toast.error(action.payload);
				})

				//delete message
				.addCase(deleteMessage.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(deleteMessage.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.isError = false;
					state.messages = state.messages.filter(message => message._id !== action.payload);
          // toast.success(action.payload);
				})
				.addCase(deleteMessage.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					state.message = action.payload;
					toast.error(action.payload);
				})

				.addCase(deleteFile.fulfilled, (state, action) => {
				    const messageId = action.payload.messageId;
				    state.isLoading = false;
				    state.isSuccess = true;
				    state.isError = false;
				    state.messages = state.messages.map(message => {
				        if (message._id === messageId) {
				            // Filter out the deleted file from the message's files array
				            message.files = message.files.filter(file => file.file !== action.payload.fileUrl);
				        }
				        return message;
				    });
				    // state.messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
				    // toast.success('File deleted successfully'); // Example of showing a success toast
				})

				//delete conversation
				.addCase(deleteConversation.pending, (state)=>{
					state.isLoading = true;
				})
				.addCase(deleteConversation.fulfilled, (state, action)=>{
					state.isLoading = false;
					state.isSuccess = true;
					state.isError = false;
					state.conversations = state.conversations.filter(conversation => conversation._id !== action.payload);
					// toast.success(action.payload);
				})
				.addCase(deleteConversation.rejected, (state, action)=>{
					state.isLoading = false;
					state.isError = true;
					// state.conversation = action.payload;
					toast.error(action.payload);
				})
  	}
});

export const {
	setActiveConversation,
	updateMessagesAndConversations,
	addFiles,
  clearFiles,
  removeFileFromFiles,
	} = chatSlice.actions;
export const selectConversations = (state) => state.chat.conversations;

export default chatSlice.reducer;