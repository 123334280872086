import React,{useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import authService from "../redux/features/auth/authService";

// const useRedirectLoggedOutUser = (path) => {

// 	// const navigate = useNavigate();

// 	// useEffect(() =>{

// 	// 	let isLoggedIn;

// 	// 	const redirectLoggedOutUser = async () =>{
// 	// 		try{
// 	// 			isLoggedIn = await authService.getLoginStatus();
// 	// 		}catch(error){
// 	// 			console.log(error.message);
// 	// 		}

// 	// 		if (!isLoggedIn) {
// 	// 	        toast.info("Session expired, please login to continue");
// 	// 	        navigate(path);
// 	// 	        return;
// 	// 	    }
// 	// 	};
// 	// 	redirectLoggedOutUser();
// 	// },[path, navigate]);
// 	const navigate = useNavigate();
//     const [sessionExpired, setSessionExpired] = useState(false);
//     const [loginChecked, setLoginChecked] = useState(false);

//     useEffect(() => {
//         let isLoggedIn = true;

//         const checkLoginStatus = async () => {
//             try {
//                 isLoggedIn = await authService.getLoginStatus();
//                 // console.log('Login status:', isLoggedIn); // Debug log
//             } catch (error) {
//                 console.log('Error getting login status:', error.message);
//                 isLoggedIn = false;
//             }

//             if (!isLoggedIn && !sessionExpired) {
//                 setSessionExpired(true);
//                 toast.info("Session expired, please login to continue");
//                 navigate(path);
//             }
//         };

//         checkLoginStatus();

//         const interval = setInterval(() => {
//             checkLoginStatus();
//         }, 3 * 60 * 60 * 1000); // 3 hours interval

//         return () => clearInterval(interval); // Cleanup the interval on unmount
//     }, [path, navigate, sessionExpired]);

//     useEffect(() => {
//         if (sessionExpired) {
//             window.location.reload();
//         }
//     }, [sessionExpired]);

//     return sessionExpired;

// };

const useRedirectLoggedOutUser = (path) => {
    const navigate = useNavigate();
    const [sessionExpired, setSessionExpired] = useState(false);
    const [loginChecked, setLoginChecked] = useState(false);

    useEffect(() => {
        let isLoggedIn = true;

        const checkLoginStatus = async () => {
            try {
                isLoggedIn = await authService.getLoginStatus();
            } catch (error) {
                console.log('Error getting login status:', error.message);
                isLoggedIn = false;
            }

            if (!isLoggedIn && !sessionExpired) {
                setSessionExpired(true);
                toast.info("Session expired, please login to continue");
                navigate(path);
            }
            setLoginChecked(true);
        };

        checkLoginStatus();

        const interval = setInterval(() => {
            checkLoginStatus();
        }, 3 * 60 * 60 * 1000); // 3 hours interval

        return () => clearInterval(interval); // Cleanup the interval on unmount
    }, [path, navigate, sessionExpired]);

    useEffect(() => {
        if (sessionExpired && loginChecked) {
            authService.logout(); // Log out the user
            navigate(path); // Redirect to the specified path (login page)
        }
    }, [sessionExpired, loginChecked, navigate, path]);

    return sessionExpired;
};

export default useRedirectLoggedOutUser