import React,{useEffect, useState} from 'react';
import { CloseIcon, EmojiIcon } from "../../svg";
import EmojiPicker from "emoji-picker-react";

const EmojiPickerApp = ({textRef, message, setMessage, showPicker, setShowPicker, setShowAttachments,}) => {

	const [cursorPosition, setCursorPosition] = useState();
	useEffect(() => {
	    textRef.current.selectionEnd = cursorPosition;
	}, [cursorPosition]);

	 const handleEmoji = (emojiData, e) => {
	    const { emoji } = emojiData;
	    const ref = textRef.current;
	    ref.focus();
	    const start = message.substring(0, ref.selectionStart);
	    const end = message.substring(ref.selectionStart);
	    const newText = start + emoji + end;
	    setMessage(newText);
	    setCursorPosition(start.length + emoji.length);
	};

	return (
		<>
			<li className="position-relative">
				<button className="btn" type="button"  onClick={() => {setShowAttachments(false);setShowPicker((prev) => !prev);}}>
					{showPicker ? (
			          <CloseIcon />
			        ) : (
			          <EmojiIcon />
			        )}
				</button>
				{showPicker ? (
				<div className="emojiSec">
					<EmojiPicker onEmojiClick={handleEmoji}/>
				</div>
				) : null}
			</li>	
		</>
	)
}

export default EmojiPickerApp