import React,{useState,useEffect, useRef} from 'react';
import { useDispatch, useSelector } from "react-redux";
import EmojiPickerApp from "./EmojiPickerApp";
// import VoiceMessage from "./VoiceMessage";
import Attachments from "./Attachments";
import { SendIcon } from "../../svg";
import Input from "./Input";
import { ClipLoader } from "react-spinners";
import { sendMessage } from "../../redux/features/chat/chatSlice";
import {
  getLoginStatus,getUser,selectIsLoggedIn,selectUser,
} from "../../redux/features/auth/authSlice";
import { FilterUser } from "../hiddenLinks";
import SocketContext from "../../context/SocketContext";

const ChatActions = ({socket}) => {
	const dispatch = useDispatch();
  	const [showPicker, setShowPicker] = useState(false);
  	const [showAttachments, setShowAttachments] = useState(false);
  	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [isEnabled, setIsEnabled] = useState(true);
	const [defaultUsers, setDefaultUsers] = useState([]);
	const [selectedQuery, setSelectedQuery] = useState("");

	const { activeConversation, isLoading } = useSelector((state) => state.chat);
  	const { user, users } = useSelector((state) => state.auth);
  	// const { token } = user;
  const isLoggedIn = useSelector(selectIsLoggedIn);
	// const user = useSelector(selectUser);
	// useEffect(() => {
	//     dispatch(getLoginStatus());
	//     if (isLoggedIn && user === null) {
	//       dispatch(getUser());
	//     }

	// }, [dispatch, isLoggedIn, user]);

	useEffect(() => {
    if (message === "") {
      setSelectedQuery("");
    }
  }, [message]);

  const textRef = useRef();
	const values = {
	    message,
	    chat_id: activeConversation._id,
	    files: [],
	    // token,
	};

	const SendMessageHandler = async (e) => {
	    e.preventDefault();
	    setLoading(true);
	    let newMsg = await dispatch(sendMessage(values));
	    socket.emit("send message", newMsg.payload);
	    setMessage("");
	    setLoading(false);
	};

	const handlePicklistChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedQuery(selectedValue);
    setMessage(selectedValue);
  };

	return (
		<>
		{isEnabled ? (
			
        <div className="inputSec">
          <form onSubmit={(e) => SendMessageHandler(e)}>
            <div className="inputBtn">
              <ul>
              {/*<li>
              	<VoiceMessage />
              </li>*/}
                <EmojiPickerApp 
                  textRef={textRef} 
                  message={message} 
                  setMessage={setMessage} 
                  showPicker={showPicker} 
                  setShowPicker={setShowPicker} 
                  setShowAttachments={setShowAttachments}
                />
                <Attachments 
                  showAttachments={showAttachments} 
                  setShowAttachments={setShowAttachments} 
                  setShowPicker={setShowPicker}
                />
                <FilterUser>
                <li>
	                <div className="queryPcikList">
						<select onChange={handlePicklistChange} value={selectedQuery} className="picklist">
							<option value="">Query</option>
							<option value="Good morning. I hope you are doing well. If you need any help regarding your training today, please dont hesitate to ask for help. Our trainer will get back to you as soon as possible. Thanks">
								Morning Greetings!
							</option>
							<option value="Good afternoon. I hope you are doing well. If you need any help regarding your training today, please dont hesitate to ask for help. Our trainer will get back to you as soon as possible. Thanks">
								Afternoon Greetings!
							</option>
							<option value="Hi, if you need any help regarding training today please dont hesitate to ask for help. Our trainer will get back to you as soon as possible.">
								General Help Message!
							</option>
						</select>
	                </div>
		            </li>
		            </FilterUser>
              </ul>
              <Input message={message} setMessage={setMessage} textRef={textRef} />
              <button type="submit" className="btn" disabled={!message || isLoading}>
                {isLoading && loading ? (
                  <ClipLoader color="#E9EDEF" size={20} />
                ) : (
                  <SendIcon />
                )}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <p className="disabledInputBar">Component is disabled</p>
      )}
     
		</>
	)
}

const ChatActionsWithSocket = (props) => (
  <SocketContext.Consumer>
    {(socket) => <ChatActions {...props} socket={socket} />}
  </SocketContext.Consumer>
);
export default ChatActionsWithSocket;