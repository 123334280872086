import React,{useState} from 'react';
import FilesPreviewHeader from "./FilesPreviewHeader";
import FileViewer from "./FileViewer";
import FilesPreviewInput from "./FilesPreviewInput";
import HandleAndSend from "./HandleAndSend";

const FilesPreview = () => {

	const [message, setMessage] = useState("");
  	const [activeIndex, setActiveIndex] = useState(0);

	return (
		<>
			<div className="position-relative d-flex align-items-center flex-column fileViewerSec">
				<FilesPreviewHeader activeIndex={activeIndex}/>
				<FileViewer activeIndex={activeIndex}/>
				<div className="filePreviewBottom">
					<FilesPreviewInput message={message} setMessage={setMessage}/>
					<HandleAndSend activeIndex={activeIndex} setActiveIndex={setActiveIndex} message={message}/>
				</div>
			</div>
		</>
	)
}

export default FilesPreview