// import { createSelector } from '@reduxjs/toolkit';

export const getConversationId = (user, users) => {
  return users[0]?._id === user?._id ? users[1]?._id : users[0]?._id;
};

export const getConversationName = (user, users) => {
  return users[0]?._id === user?._id ? users[1]?.name : users[0]?.name;
};
export const getConversationEmail = (user, users) => {
  return users[0]?._id === user?._id ? users[1]?.email : users[0]?.email;
};
export const getConversationPicture = (user, users) => {
  return users[0]?._id === user?._id ? users[1]?.picture : users[0]?.picture;
};

export const checkOnlineStatus = (onlineUsers, user, users) => {
  let chatId = getConversationId(user, users);
  let check = onlineUsers.find((u) => u.userId === chatId);
  return check ? true : false;
};

// export const selectActiveConversationName = createSelector(
//   [(state) => state.chat.activeConversation, (state) => state.auth.user],
//   (activeConversation, user) => {
//     if (!activeConversation || !user) return '';
//     const users = activeConversation.users || [];
//     return users[0]?._id === user?._id ? users[1]?.name : users[0]?.name;
//   }
// );