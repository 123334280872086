// export const getFileType = (memType) => {
//   switch (memType) {
//     case "text/plain":
//       return "TXT";
//     case "application/pdf":
//       return "PDF";
//     case "application/msword":
//     case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
//       return "DOCX";
//     case "application/vnd.ms-powerpoint":
//     case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
//       return "PPTX";
//     case "application/vnd.ms-excel":
//     case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
//       return "XLSX";
//     case "application/vnd.rar":
//       return "RAR";
//     case "application/zip":
//       return "ZIP";
//     case "audio/mpeg":
//     case "audio/wav":
//       return "AUDIO";
//     case "video/mp4":
//     case "video/mpeg":
//     case "video/mpg":
//     case "application/x-troff-msvideo":
//     case "video/avi":
//     case "video/msvideo":
//     case "video/x-msvideo":  
//       return "VIDEO";
//     default:
//       return "IMAGE";
//   }
// };

export const getFileType = (memType) => {
  switch (memType) {
    case "text/plain":
      return "TXT";
    case "application/pdf":
      return "PDF";
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "DOCX";
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "PPTX";
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "XLSX";
    case "application/vnd.rar":
      return "RAR";
    case "application/zip":
      return "ZIP";
    case "audio/mpeg":
    case "audio/wav":
      return "AUDIO";
    case "video/mp4":
    case "video/mpeg":
    case "application/x-troff-msvideo":
    case "video/avi":
    case "video/msvideo":
    case "video/x-msvideo":
      return "VIDEO";
    case "image/jpeg":
    case "image/png":
    case "image/gif":
    case "image/webp":
      return "IMAGE";
    default:
      return "DEFAULT"; // Return a generic file type for unsupported MIME types
  }
};