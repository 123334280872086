import React,{useState} from 'react';
import DashboardHeader from './DashboardHeader.js';
import DashboardSideBar from './DashboardSideBar.js';
import SelectedUserChat from './SelectedUserChat.js';
import { useDispatch,useSelector } from "react-redux";
import useRedirectLoggedOutUser from "../../customHook/useRedirectLoggedOutUser";
import axios from "axios";
import { toast } from "react-toastify";

const Dashboard = () => {

	useRedirectLoggedOutUser('/login')	

	const { user } = useSelector((state) => state.auth);
	const [selectedChat, setSelectedChat] = useState(null);
	const [activeChatId, setActiveChatId] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);

	const handleChatSelect = async (chatId) => {
		setActiveChatId(chatId);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/message/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSelectedChat(data);
    } catch (error) {
      toast.error('Failed to Load Conversation');
    }
  };
  console.log(selectedUser)

	return (
		<>
			<DashboardHeader />
			<div className="mainContainer">
				<DashboardSideBar handleChatSelect={handleChatSelect} activeChatId={activeChatId} selectedUser={selectedUser} setSelectedUser={setSelectedUser}/>
				<div className="dashboardContainer">
				{selectedChat ? (
		            <SelectedUserChat selectedChat={selectedChat} selectedUser={selectedUser}/>
		        ) : (
		            <div className="dashboardContent">
					<h1>ACDAP</h1>
					<span>Association of Certified</span>
					<span>Digital Accounting Professionals</span>
					</div>
		        )}
				</div>
			</div>	
		</>
	)
}

export default Dashboard