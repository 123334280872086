import React from 'react';
import { Link } from 'react-router-dom';
import moment from "moment";
import { dateHandler } from "../../utils/date";
import { toast } from "react-toastify";
import FileImageVideo from "./FileImageVideo";
import FileOthers from "./FileOthers";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { FilterUser } from "../hiddenLinks";
import AWS from "aws-sdk";

const bucketAccess = process.env.REACT_APP_ACCESS_KEY;
const bucketSecret = process.env.REACT_APP_SECRET_KEY;
const bucketName = process.env.REACT_APP_BUCKET_NAME;
const bucketRegion = process.env.REACT_APP_REGION;

export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

const FileMessage = ({ FileMessage, message, me, confirmDelete }) => {
	const { file, type, name, size } = FileMessage;

	AWS.config.update({
  accessKeyId: bucketAccess,
  secretAccessKey: bucketSecret,
  region: bucketRegion 
});

const s3 = new AWS.S3();

	const deleteFileFromS3 = async () => {
  const params = {
        Bucket: bucketName,
        Key: `chat/${name}`, 
      };
  try {
    await s3.deleteObject(params).promise();
    // If deletion succeeds, you can log a success message or trigger any necessary actions
    // console.log("File deleted successfully from S3");
  } catch (error) {
    // If an error occurs during deletion, log the error message
    console.error("Error deleting file from S3:", error);
    // Optionally, you can show an error message to the user
    toast.error(`Error deleting file: ${error.message}`);
  }
};
	
	const formattedDate = message.seen 
    ? `Read on ${new Date(message.updatedAt).toLocaleString('en-US', { 
        weekday: 'long', 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: '2-digit', 
        minute: '2-digit' 
      })}` 
    : '-';

	return (
		<>
			<div className={`d-flex ${me ? "ml-auto justify-content-end " : ""}`}>
				{!me && message.conversation?.isGroup && (
		          <div className="senderImg">
		            <img
		              src={message.sender.picture}
		              alt=""
		              className="w-8 h-8 rounded-full"
		            />
		          </div>
		        )}
				<div className={`position-relative textBox ${me ? "messageBg1" : "messageBg2"}`} id={message._id} title={formattedDate}>
				{!me && message.conversation?.isGroup && (<small className="grpUserName">{shortenText(message.sender.name, 12)}</small>)}
				<div className={`d-flex ${me ? "flex-row-reverse" : "flex-row"} justify-space-between`}>
					<div className="">
						{type === "IMAGE" || type === "VIDEO" ? (
			              <FileImageVideo name={name} url={file} type={type} />
			            ) : (
			              <FileOthers size={size} name={name} file={file} type={type} me={me} />
			            )}
						{/*<span className="text-white">{moment(message.createdAt).format("HH:mm")}</span>*/}
						<span className="text-white">{message?.createdAt ? dateHandler(message?.createdAt) : " "}</span>
						<div className="message-info">
                {me && (
                    <span className="message-status">
                        {message.seen ? '✓✓' : '✓'}
                    </span>
                )}
            </div>
					</div>
					<FilterUser>
					{
						me ?
						<div className="customDropDownButton">
			        {['start'].map(
			          (direction) => (
			            <DropdownButton
			              key={direction}
			              id={`dropdown-button-drop-${direction}`}
			              drop={direction}
			              title={`...`}
			            >
			              {/*<li><Link className="dropdown-item" onClick={() => getModule(message._id)}>Edit</Link></li>*/}
								    <li><Link className="dropdown-item" to="#" onClick={() => { confirmDelete(message._id); deleteFileFromS3();}}>Delete</Link></li>
			            </DropdownButton>
			          ),
			        )}
			      </div>
			      :
			      <div className="customDropDownButton">
			        {['end'].map(
			          (direction) => (
			            <DropdownButton
			              key={direction}
			              id={`dropdown-button-drop-${direction}`}
			              drop={direction}
			              title={`...`}
			            >
			              {/*<li><Link className="dropdown-item" onClick={() => getModule(message._id)}>Edit</Link></li>*/}
								    <li><Link className="dropdown-item" to="#" onClick={() => { confirmDelete(message._id); deleteFileFromS3();}}>Delete</Link></li>
			            </DropdownButton>
			          ),
			        )}
			      </div> 
					}
					</FilterUser>
				</div>
				</div>
			</div>
		</>
	)
}

export default FileMessage