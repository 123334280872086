import { useEffect, useState } from "react";
import { CloseIcon, ValidIcon } from "../../../svg";
import ReactAudioPlayer from 'react-audio-player';

const Ringing = ({ call, setCall, answerCall, endCall }) => {

	const { receiveingCall, callEnded, name, picture } = call;
	const [timer, setTimer] = useState(0);
	let interval;
	const handleTimer = () => {
	    interval = setInterval(() => {
	      setTimer((prev) => prev + 1);
	    }, 1000);
	};
	console.log(timer);
	
	useEffect(() => {
	    if (timer <= 25) {
	      handleTimer();
	    } else {
	      setCall({ ...call, receiveingCall: false });
	    }
	    return () => clearInterval(interval);
	}, [timer]);

	return (
		<>
			<div className="ringingSec">
				<div className="ringingContent">
					<img
			            src={picture}
			            alt={`caller profile picture`}
			            className=""
			        />
			        <div className="">
				        <h5>{name}</h5>
				        <span>Whatsapp video...</span>
			        </div>
			        <ul>
			        	<li onClick={endCall} className="closeCall"><CloseIcon /></li>
			        	<li onClick={answerCall} className="acceptCall"><ValidIcon /></li>
			        </ul>
		        </div>
		        <ReactAudioPlayer
				  src="../../../audio/ringtone.mp3"
				  autoPlay={true}
				  loop
				  // controls
				/>
			</div>
		</>
	)
}

export default Ringing